import { AddOnModelDto } from '../../api/v1';
import { AddOnDto } from '../../api/v2';

export const mapAddonsNamesAndDescriptions = (
  addOns: AddOnModelDto[] | undefined | null,
  addOnsMeta: AddOnDto[] | undefined
) =>
  addOns?.map((addOn: AddOnModelDto) => {
    const currentAddonMeta = addOnsMeta?.find(
      (meta: AddOnDto) => meta.identifier === addOn.type?.code?.toLowerCase()
    );

    return {
      ...addOn,
      description: currentAddonMeta?.description || '',
      included: currentAddonMeta?.included || null,
    };
  });
