/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';

/**
 * Creates an axios interceptor which will intercept any 401 response
 * and attempt to refresh the auth token, before re-attempting the request
 */
export const useTokenRefreshInterceptor = () => {
  const { signinSilent, removeUser } = useAuth();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const userAuth = await signinSilent();
            if (userAuth?.access_token) {
              originalRequest.headers.Authorization = `Bearer ${userAuth?.access_token}`;
              return axios(originalRequest);
            }
          } catch (e) {
            removeUser();
            return Promise.reject(error);
          }

          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [removeUser, signinSilent]);
};
