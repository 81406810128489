import { useField } from 'formik';
import { useMemo, useRef } from 'react';
import { useFocusOnError, useGetProductSchemeAddons } from '../../../../../hooks';
import { BreakdownOptions, BreakdownOptionsProps } from './BreakdownOptions';
import { AddOn } from '../../../../../types';
import { AddOnDto } from '../../../../../api/v2';

type FormCoverTypeRadioExtendedProps = {
  id: string;
  name: string;
  onChangeOptional?: (option: AddOn) => void;
};

type FormCoverTypeRadioProps = Omit<
  BreakdownOptionsProps,
  'value' | 'onChange' | 'onBlur' | 'error'
> &
  FormCoverTypeRadioExtendedProps;

export const BreakdownAddonRadio = ({
  testId,
  onChangeOptional,
  options,
  ...inputProps
}: FormCoverTypeRadioProps) => {
  const [field, meta] = useField(inputProps);
  const fieldRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef, name: inputProps.name });
  const { data } = useGetProductSchemeAddons();

  const properNameOptions = useMemo(
    () =>
      options?.map((option: AddOn) => {
        const addonDesc = data?.find(
          (val: AddOnDto) => val.identifier === option?.identifier
        );
        return {
          ...option,
          name: addonDesc?.description || '',
          included: addonDesc?.included,
          notIncluded: addonDesc?.notIncluded,
        };
      }),
    [data, options]
  );
  const onChange = (option: AddOn) => {
    if (onChangeOptional) {
      onChangeOptional(option);
    }
  };

  const error = meta.touched && meta.error ? meta.error : undefined;

  return (
    <div className="relative form-field-spacing" data-testid={testId} ref={fieldRef}>
      <BreakdownOptions
        testId={testId}
        {...inputProps}
        {...field}
        onChange={onChange}
        error={error}
        aria-errormessage={`${inputProps.id}_error`}
        cols={{ desktop: 2, tablet: 1, mobile: 1 }}
        options={properNameOptions}
      />
    </div>
  );
};
