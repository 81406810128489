import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiError, Dayinsure, MotorPolicySummaryDto, OpenAPI } from '../../../api/v1';
import { query } from '../queryKeys';
import { PaymentContext } from '../../../contexts/PaymentContext';

export const useMotorPolicySummaryQuery = (
  motorQuoteId?: string | undefined,
  state?: string[],
  interval?: boolean,
  mta?: boolean
) => {
  const navigate = useNavigateWithReferrer();
  const { setPolicyDelayed } = useContext(PaymentContext);
  const [fetched, setFetched] = useState(!interval);
  const [attemptsNo, setAttemptsNo] = useState(0);
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getPolicies = () => {
    const api = new Dayinsure(OpenAPI);

    return api.motorPolicy.getMotorPolicy1(state, motorQuoteId);
  };

  return useQuery<MotorPolicySummaryDto[], ApiError>(
    query.motorPolicySummary(state),
    getPolicies,
    {
      retry: (count, error: ApiError) => error.status !== 404 && count < 3,
      enabled: motorQuoteId !== undefined || state !== undefined,
      cacheTime: 0,
      refetchInterval: !fetched && interval ? 2000 : false,
      onSuccess: data => {
        setAttemptsNo((attempts: number) => attempts + 1);
        if (data.length > 0 && !fetched) {
          setFetched(true);
          setPolicyDelayed(false);
        }
        if (attemptsNo > 100) {
          if (mta) {
            navigate('../../../../empty');
          } else {
            setPolicyDelayed(true);
          }
        }
        return data;
      },
      onError: err => {
        if (err.status === 422) {
          setGlobalError(err, {
            message: 'Something doesn’t look quite right, please go back and try again.',
          });
          setFetched(true);
          return;
        }

        if (err.status !== 404) {
          setGlobalError(err);
          setFetched(true);
        }
      },
    }
  );
};
