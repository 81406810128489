import { useScript } from '@dayinsure/shared';
import { env } from '../configuration/env';

const useCookieConsent = () => {
  const showCookieConsent = env.config.SHOW_COOKIE_CONSENT === 'true';

  useScript(
    showCookieConsent
      ? `https://cdn-ukwest.onetrust.com/consent/${env.config.COOKIE_CONSENT_KEY}/OtAutoBlock.js`
      : ''
  );
  useScript(
    showCookieConsent
      ? `https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js`
      : '',
    {
      'data-domain-script': env.config.COOKIE_CONSENT_KEY || '',
      charset: 'UTF-8',
    }
  );
  return undefined;
};

export { useCookieConsent };
