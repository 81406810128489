import type { OpenAPIConfig } from '../core/OpenAPI';

import { PersonService } from './services/PersonService';
import { ProductService } from './services/ProductService';
import { BaseAPI } from '../core/BaseAPI';

export class Dayinsure extends BaseAPI {
  readonly person: PersonService;
  readonly product: ProductService;

  constructor(config?: Partial<OpenAPIConfig>) {
    super(config);

    this.person = new PersonService(this.request);
    this.product = new ProductService(this.request);
  }
}
