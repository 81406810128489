import type { AddOnDto } from '../models/AddOnDto';
import type { CoverDto } from '../models/CoverDto';
import type { CoverOptionsDto } from '../models/CoverOptionsDto';
import type { DriverOptionsDto } from '../models/DriverOptionsDto';
import type { EndorsementsDto } from '../models/EndorsementsDto';
import type { LicenceDurationsDto } from '../models/LicenceDurationsDto';
import type { OptionCodeDto } from '../models/OptionCodeDto';
import type { VehicleOptionsDto } from '../models/VehicleOptionsDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class ProductService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getApiV2ProductSchemeAddons(
    productName: string,
    productVersion: string,
    schemeName: string
  ): CancelablePromise<AddOnDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/addons',
      path: {
        productName,
        productVersion,
        schemeName,
      },
    });
  }

  getApiV2ProductCanbusImmobilisers(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/canbus-immobilisers',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductClaimTypes(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/claim-types',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductConvictionTypes(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/conviction-types',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductSchemeCover(
    productName: string,
    productVersion: string,
    schemeName: string,
    coverType: string
  ): CancelablePromise<CoverDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/cover/{coverType}',
      path: {
        productName,
        productVersion,
        schemeName,
        coverType,
      },
    });
  }

  getApiV2ProductSchemeCoverOptions(
    productName: string,
    productVersion: string,
    schemeName: string
  ): CancelablePromise<CoverOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/cover-options',
      path: {
        productName,
        productVersion,
        schemeName,
      },
    });
  }

  getApiV2ProductDriverOptions(
    productName: string,
    productVersion: string
  ): CancelablePromise<DriverOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/driver-options',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductDrivingQualifications(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/driving-qualifications',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductEmploymentIndustries(
    productName: string,
    productVersion: string,
    match?: string,
    take?: number
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/employment-industries',
      path: {
        productName,
        productVersion,
      },
      query: {
        match,
        take,
      },
    });
  }

  getApiV2ProductEmploymentStatuses(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/employment-statuses',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductSchemeEndorsements(
    productName: string,
    productVersion: string,
    schemeName: string
  ): CancelablePromise<EndorsementsDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/endorsements',
      path: {
        productName,
        productVersion,
        schemeName,
      },
    });
  }

  getApiV2ProductLicenceDurations(
    productName: string,
    productVersion: string
  ): CancelablePromise<LicenceDurationsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/licence-durations',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductMedicalConditions(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/medical-conditions',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductOccupations(
    productName: string,
    productVersion: string,
    match?: string,
    take?: number
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/occupations',
      path: {
        productName,
        productVersion,
      },
      query: {
        match,
        take,
      },
    });
  }

  getApiV2ProductOtherVehicleUsages(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/other-vehicle-usages',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductStudentOccupations(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/student-occupations',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductVehicleModifications(
    productName: string,
    productVersion: string,
    match?: string,
    take?: number
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-modifications',
      path: {
        productName,
        productVersion,
      },
      query: {
        match,
        take,
      },
    });
  }

  getApiV2ProductVehicleOptions(
    productName: string,
    productVersion: string
  ): CancelablePromise<VehicleOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-options',
      path: {
        productName,
        productVersion,
      },
    });
  }

  getApiV2ProductVehicleOwner(
    productName: string,
    productVersion: string
  ): CancelablePromise<OptionCodeDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-owner',
      path: {
        productName,
        productVersion,
      },
    });
  }
}
