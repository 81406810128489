import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  ApiError,
  Dayinsure as DayInsureAPI,
  OpenAPI,
  PaymentMethodResponseIAPIResult,
} from '../../../api/v1';
import { query } from '../../queries';

type QueryMutationType = {
  paymentMethodId: string;
};

export const useRemovePaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);

  const removePayment = ({ paymentMethodId }: QueryMutationType) => {
    if (!paymentMethodId) {
      setGlobalError('No payment method ID');
      throw new Error('No payment method ID');
    }
    const api = new DayInsureAPI(OpenAPI);
    return api.paymentMethod.deletePaymentMethod(paymentMethodId);
  };

  return useMutation(removePayment, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData<PaymentMethodResponseIAPIResult>(
        query.paymentMethods,
        paymentMethod => {
          const updatedPaymentMethods =
            paymentMethod?.detail?.storedPaymentMethods?.filter(
              method => method.id !== variables.paymentMethodId
            );
          return {
            ...paymentMethod,
            detail: {
              storedPaymentMethods: updatedPaymentMethods,
            },
          };
        }
      );
    },
    onError(error: ApiError) {
      if (error?.status !== 404) {
        setGlobalError(error);
      }
    },
  });
};
