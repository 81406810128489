import type { VehicleIAPIResult } from '../models/VehicleIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class VehicleService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getVehicle(vehicleRegistration: string): CancelablePromise<VehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleRegistration}',
      path: {
        vehicleRegistration,
      },
    });
  }
}
