import {
  GlobalErrorContext,
  useNavigateWithReferrer,
  useSignIn,
} from '@dayinsure/shared';
import { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv1,
  MotorPolicyDto,
  OpenAPI,
} from '../../../api/v1';
import { query } from '../queryKeys';
import { useReferrer } from '../../useReferrer';

export const usePolicyQuery = (
  id: string | undefined | null,
  version?: number | undefined,
  freshData?: boolean,
  isGuest?: boolean,
  isFullAccount?: boolean,
  goBackOnError?: boolean
) => {
  const navigate = useNavigateWithReferrer();
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);
  const referrer = useReferrer();
  const { signIn } = useSignIn(referrer.name, isGuest);

  useEffect(() => {
    if (freshData) {
      queryClient.invalidateQueries(query.policy(id || '', version));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPolicy = () => {
    if (!id) {
      throw new Error('No policy ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorPolicy.getMotorPolicy(id, version);
  };

  return useQuery<MotorPolicyDto, ApiError>(query.policy(id || '', version), getPolicy, {
    keepPreviousData: true,
    enabled: id !== undefined,
    onError: async err => {
      if (err.status === 401 && !isFullAccount) {
        signIn();
      }
      if (err.status !== 401) {
        setGlobalError(err, {
          onClose: () => {
            if (goBackOnError) {
              navigate(-1);
            }
          },
        });
      }
    },
  });
};
