import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  GlobalErrorContext,
  useNavigateWithReferrer,
  useSignIn,
} from '@dayinsure/shared';
import { query } from '../queryKeys';
import {
  Dayinsure as DayinsureAPIv1,
  OpenAPI,
  ApiError,
  GetMotorQuoteResponseDto,
} from '../../../api/v1';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { useReferrer } from '../../useReferrer';

export const useQuoteQuery = (
  id: string | undefined,
  isGuest?: boolean,
  isFullAccount?: boolean
) => {
  const navigate = useNavigateWithReferrer();
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const { setIsLoading } = useContext(QuoteJourneyStatusContext);
  const referrer = useReferrer();
  const { signIn } = useSignIn(referrer.name, isGuest);

  const getQuote = () => {
    if (!id) {
      throw new Error('No quote ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorQuote.getMotorQuote(id);
  };

  return useQuery<GetMotorQuoteResponseDto, ApiError>(
    query.quoteDetails(id || ''),
    getQuote,
    {
      onSuccess: data => {
        const formIsDeclined = !!(data?.declinatures && data?.declinatures?.length > 0);
        if (formIsDeclined) {
          setIsLoading(true);
          setGlobalError(null, {
            title: 'Sorry, we can’t give you a quote',
            message: `We’ve looked at the details you’ve given us, and unfortunately, we can’t give you a quote at this time.`,
            cta: 'Go back and try again',
            ctaId: 'quote-decline_cta',
            onClose: () => {
              navigate(-1);
              clearGlobalError();
              setIsLoading(false);
            },
          });
        }
      },
      enabled: id !== undefined,
      onError: err => {
        // Don't show a global error on 401. We want to handle this manually.
        if (err.status === 401 && !isFullAccount) {
          signIn();
        }
        if (!(err?.status === 401)) {
          setGlobalError(err);
        }
      },
    }
  );
};
