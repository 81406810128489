import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  CreateMotorQuoteRequestDto,
  Dayinsure as DayInsureAPI,
  OpenAPI,
  CreateMotorQuoteResponseDto,
  ApiError,
} from '../../../api/v1';
import { query } from '../../queries';

export const useCreateQuoteMutation = () => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);

  const createQuote = (requestBody: CreateMotorQuoteRequestDto) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.motorQuote.postMotorQuote(requestBody);
  };

  return useMutation(createQuote, {
    mutationKey: 'create-quote',
    onSuccess: data => {
      queryClient.setQueryData<CreateMotorQuoteResponseDto>(
        query.quoteCreate(data.id || ''),
        quote => {
          return {
            ...data,
            ...quote,
          };
        }
      );
    },
    onError: (err: ApiError) => {
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
      }
    },
  });
};
