export const DEFAULT_FOCUS_STATES = {
  encryptedCardNumber: false,
  encryptedExpiryDate: false,
  encryptedSecurityCode: false,
  encryptedExpiryMonth: false,
  encryptedExpiryYear: false,
};

export const DEFAULT_ERROR_STATES = {
  encryptedCardNumber: '',
  encryptedExpiryDate: '',
  encryptedExpiryMonth: '',
  encryptedExpiryYear: '',
  encryptedSecurityCode: '',
};

export const ORDER_STATES = {
  processing: 'processing',
  complete: 'complete',
  authorised: 'authorised',
  failed: 'failed',
};

export const EXCESSES = {
  policy: 'POLICY_XS',
  windscreenRepaier: 'WINDSCREEN_REPAIR_XS',
  windscreenReplace: 'WINDSCREEN_REPLACE_XS',
  nonApprovedRepair: 'NON_APPROVED_REPAIR_XS',
  theftOfKeys: 'THEFT_OF_KEYS_XS',
  youngDriverKeys: 'YOUNG_DRIVER_XS',
} as const;

export enum PaymentTypesEnum {
  ANNUAL = 'ANN',
  MONTHLY = 'MON',
}

export enum PaymentPlansEnum {
  ANN = 'FULL_CARD_V1.4',
  MON = 'PREMIUM_FINANCE',
}
