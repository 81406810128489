import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  ApiError,
  CancelablePromise,
  Dayinsure as DayInsureAPI,
  OpenAPI,
  PlaceOrderRequestDto,
  PlaceOrderResponseDto,
} from '../../../api/v1';
import { query } from '../../queries';

export const useMakeOrderMutation = () => {
  const queryClient = useQueryClient();
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);

  const makeOrder = (
    requestBody: PlaceOrderRequestDto
  ): CancelablePromise<PlaceOrderResponseDto> => {
    const api = new DayInsureAPI(OpenAPI);
    return api.order.postOrder(requestBody);
  };
  return useMutation(makeOrder, {
    onSuccess: (data: PlaceOrderResponseDto) => {
      queryClient.setQueryData<PlaceOrderResponseDto>(
        query.makeOrder(data?.orderId || ''),
        () => data
      );
    },
    onError: (err: ApiError) => {
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
          onClose: clearGlobalError,
        });
      }
    },
  });
};
