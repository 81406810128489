import type { ConvertToCustomerRequest } from '../models/ConvertToCustomerRequest';
import type { IAPIResult } from '../models/IAPIResult';
import type { MarketingPreferenceIEnumerableIAPIResult } from '../models/MarketingPreferenceIEnumerableIAPIResult';
import type { PersonIAPIResult } from '../models/PersonIAPIResult';
import type { UpdatePersonRequest } from '../models/UpdatePersonRequest';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PersonService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getPerson(id: string): CancelablePromise<PersonIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}',
      path: {
        id,
      },
    });
  }

  putPerson(
    id: string,
    requestBody?: UpdatePersonRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}',
      path: {
        id,
      },
      body: requestBody,
    });
  }

  getPersonMarketingPreferences(
    personId: string
  ): CancelablePromise<MarketingPreferenceIEnumerableIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/marketing-preferences',
      path: {
        personId,
      },
    });
  }

  putPersonConvertToCustomer(
    id: string,
    requestBody?: ConvertToCustomerRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/convert-to-customer',
      path: {
        id,
      },
      body: requestBody,
    });
  }
}
