import { useMutation } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  ApiError,
  Dayinsure as DayinsureAPIv1,
  OpenAPI,
  SavePaymentMethodRequest,
  SavePaymentMethodResponseIAPIResult,
} from '../../../api/v1';

export const useAddPaymentMethodMutation = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const addPaymentMethod = (paymentMethodData: SavePaymentMethodRequest) => {
    const api = new DayinsureAPIv1(OpenAPI);

    return api.paymentMethod.postPaymentMethod(paymentMethodData);
  };

  return useMutation(addPaymentMethod, {
    onSuccess: (data: SavePaymentMethodResponseIAPIResult) => {
      if (data.detail?.resultCode === 'Refused') {
        throw new ApiError(
          {
            statusText: `${data.detail?.resultCode}`,
            status: 404,
            body: {
              error: data.detail?.resultCode || 'error',
            },
            url: '',
            ok: true,
          },
          `${data.detail?.resultCode}`
        );
      }
    },
    onError: (err: ApiError) => {
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
      }
    },
  });
};
