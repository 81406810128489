import { useContext, useEffect, useState } from 'react';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { useGetOrder, useMotorPolicySummaryQuery } from '../queries';
import { PaymentContext } from '../../contexts/PaymentContext';
import { QuoteJourneyStatusContext } from '../../contexts';
import { PolicyMtaPaths, QuoteJourneyRoutes } from '../../routes';
import { ORDER_STATES } from '../../constants/payments';
import { getQuoteId } from '../../helpers';

export const usePaymentProcessing = (mta?: boolean) => {
  const params = useParams<{ quoteId?: string; id?: string }>();
  const quoteId = mta
    ? params?.quoteId || getQuoteId(params) || ''
    : params?.quoteId || params?.id || '';
  const [motorQuoteId, setMotorQuoteId] = useState<string | undefined>(undefined);
  const [getOrderEnabled, setGetOrderEnabled] = useState(false);
  const [isRefund, setIsRefund] = useState<boolean>(false);
  const navigate = useNavigateWithReferrer();
  const { setOrderId, orderId, setCardFormVisible } = useContext(PaymentContext);
  const policy = useMotorPolicySummaryQuery(motorQuoteId, undefined, true, mta);
  const { setIsLoading, setCopy } = useContext(QuoteJourneyStatusContext);
  const { setGlobalError } = useContext(GlobalErrorContext);

  const onOrderMaxAttemptReached = (message?: string | null) => {
    setGetOrderEnabled(false);
    setOrderId(null);
    setGlobalError(null, {
      title: 'Something went wrong',
      message:
        message ||
        `There has been a problem processing your payment. Please go back and try again.`,
      cta: 'Go back',
      ctaId: 'payment-decline_cta',
      onClose: () => {
        setIsLoading(false);
      },
    });
  };

  const { data } = useGetOrder(orderId, getOrderEnabled, onOrderMaxAttemptReached);

  const processPayment =
    (refund = false) =>
    () => {
      setIsRefund(refund);
      setCopy({
        title: 'Processing payment',
        subtitle: 'Please wait while we process your payment',
      });

      setIsLoading(true);
      setCardFormVisible(true);
      setGetOrderEnabled(true);
      setTimeout(() => {
        setCopy({
          title: 'Processing documents',
          subtitle: `Your policy has been created and we have processed your payment. We are generating your documents.`,
        });
      }, 15000);
    };

  useEffect(() => {
    if (
      !motorQuoteId &&
      (data?.status === ORDER_STATES.authorised ||
        data?.status === ORDER_STATES.complete) &&
      quoteId
    ) {
      setMotorQuoteId(quoteId);
    }

    if (mta && isRefund && data?.status === ORDER_STATES.processing) {
      setMotorQuoteId(undefined);
      setGetOrderEnabled(false);
      navigate(`${quoteId}/${PolicyMtaPaths.ChangeComplete}`);
      setOrderId(null);
      setIsLoading(false);
      setIsRefund(false);
      return;
    }

    if (policy?.data && policy?.data?.length > 0 && motorQuoteId) {
      const completePageLink = !mta
        ? `../${QuoteJourneyRoutes.PurchaseComplete}`
        : `${quoteId}/${PolicyMtaPaths.ChangeComplete}`;
      setMotorQuoteId(undefined);
      setGetOrderEnabled(false);
      navigate(completePageLink);
      setOrderId(null);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, policy.data, isRefund]);
  return { processPayment };
};
