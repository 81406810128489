import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  ApiError,
  Dayinsure as DayInsureAPI,
  OpenAPI,
  QuoteIAPIResult,
  UpdateMotorQuoteRequestDto,
} from '../../../api/v1';
import { query } from '../../queries';

type QueryMutationType = {
  id: string;
} & UpdateMotorQuoteRequestDto;

export const useUpdateQuoteMutation = () => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);

  const updateQuote = ({ id, ...requestBody }: QueryMutationType) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.motorQuote.putMotorQuote(id, requestBody);
  };

  return useMutation(updateQuote, {
    mutationKey: 'update-quote',
    onSuccess: (data, variables) => {
      queryClient.setQueryData<QuoteIAPIResult>(
        query.quoteDetails(variables.id),
        quote => {
          return {
            ...quote,
            detail: {
              ...quote?.detail,
            },
          };
        }
      );
    },
    onError: (err: ApiError) => {
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
      }
    },
  });
};
