import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { useAuth } from 'react-oidc-context';
import { useFormikContext } from 'formik';
import defaultsDeep from 'lodash.defaultsdeep';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { QuoteJourneyRoutes } from '../../../routes';
import {
  useGetQuoteQuery,
  useMarketingConsentCreate,
  useValidateOtpMutation,
} from '../../../hooks';
import { GetMotorQuoteResponseDto, OpenAPI as OpenAPIv1 } from '../../../api/v1';
import { OpenAPI as OpenAPIv2 } from '../../../api/v2';
import { OpenAPI as OpenAPIv3 } from '../../../api/v3';
import { QuoteJourneyFormData } from '../../../types';
import {
  hasEndorsement202Or208,
  hasEndorsement205,
  parseQuoteResponseToFormikState,
} from '../../../helpers';
import { quoteJourneyInitialFormData } from '../../../forms';
import { env } from '../../../configuration/env';

export const Pcw = () => {
  const { setIsLoading } = useContext(QuoteJourneyStatusContext);
  const { setGlobalError } = useContext(GlobalErrorContext);
  const { setValues } = useFormikContext<QuoteJourneyFormData>();
  const navigate = useNavigateWithReferrer();
  const [searchParams] = useSearchParams();
  const otp = searchParams.get('otp');
  const { mutateAsync: validateOptMutation } = useValidateOtpMutation();
  const { signinSilent } = useAuth();
  const { mutateAsync: getQuote } = useGetQuoteQuery();
  const effectCounter = useRef<number>(0);
  const createMarketingConsent = useMarketingConsentCreate();

  useLayoutEffect(() => {
    setIsLoading(true);

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToRegistrationSearchPage = () =>
    navigate(`../${QuoteJourneyRoutes.RegistrationSearch}`);

  const handleError = () =>
    setGlobalError(undefined, {
      title: 'Something went wrong',
      message: `We can’t load your quote at the moment. Please go back to the site you came from and try again, or get a new quote.`,
      cta: 'Get a new quote',
      ctaId: 'create-quote_button',
      onClose: navigateToRegistrationSearchPage,
    });

  const signUserAsGuset = async (accessToken: string, refreshToken: string) => {
    sessionStorage.setItem(
      `oidc.user:${env.config.AUTH_AUTHORITY}:${env.config.AUTH_CLIENT_ID}`,
      JSON.stringify({
        access_token: accessToken,
        refresh_token: refreshToken,
      })
    );

    const user = await signinSilent();
    OpenAPIv1.TOKEN = user?.access_token;
    OpenAPIv2.TOKEN = user?.access_token;
    OpenAPIv3.TOKEN = user?.access_token;
  };

  const mergedInitialValuesWithQuoteData = (
    data: GetMotorQuoteResponseDto
  ): QuoteJourneyFormData => {
    const formData = parseQuoteResponseToFormikState(
      data,
      data.policyCorrelationId,
      data.quoteDateTimeUtc
    );
    if (!formData) {
      throw Error('Invalid quote data');
    }

    return defaultsDeep(formData, {
      ...quoteJourneyInitialFormData,
      journeyStartPosition: QuoteJourneyRoutes.Pcw,
    });
  };

  const hydrateQuoteJourney = (values: QuoteJourneyFormData) => setValues(values);

  const navigateToNextStep = (quoteData: GetMotorQuoteResponseDto) => {
    if (
      quoteData.endorsements &&
      hasEndorsement205(quoteData.endorsements) &&
      !hasEndorsement202Or208(quoteData.endorsements)
    ) {
      navigate(`../${quoteData.id}/${QuoteJourneyRoutes.CarSecurityPolicyConditions}`);
    } else if (quoteData.endorsements && hasEndorsement202Or208(quoteData.endorsements)) {
      navigate(`../${quoteData.id}/${QuoteJourneyRoutes.CarSecurity}`);
    } else {
      navigate(`../${quoteData.id}/${QuoteJourneyRoutes.YourQuote}`);
    }
  };

  const handleContinueQuoteJourney = async (oneTimePassword: string) => {
    try {
      const { quoteId, refreshToken, accessToken } = await validateOptMutation(
        oneTimePassword
      );

      if (!refreshToken || !accessToken || !quoteId) {
        throw Error('No refresh or access token');
      }

      await signUserAsGuset(accessToken, refreshToken);
      const data = await getQuote(quoteId);
      const mergedValues = mergedInitialValuesWithQuoteData(data);
      hydrateQuoteJourney(mergedValues);
      await createMarketingConsent.mutateAsync(
        mergedValues.proposer.marketingPreferences
      );
      navigateToNextStep(data);
    } catch {
      handleError();
    }
  };

  useEffect(() => {
    if (effectCounter.current === 0) {
      // needed only on localhost, useEffect runs twice
      effectCounter.current += 1;
      if (!otp) {
        handleError();
      } else {
        handleContinueQuoteJourney(otp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};
