import { Button, Card, ContentContainer, LoadingSpinner } from '@dayinsure/components';
import { useTheme } from '@dayinsure/shared';
import { Form, useFormikContext } from 'formik';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { useLazyPreload, usePerson, useReferrer } from '../../../hooks';
import { VehicleRegSearch } from './VehicleRegSearch';
import { AgreementInfo } from './AgreementInfo';
import { LoginCTA } from '../../../components';
import { QuoteJourneyFormData } from '../../../types';
import { QuoteJourneyStatusContext } from '../../../contexts';

export const RegistrationSearch = () => {
  const { setIsLoading } = useContext(QuoteJourneyStatusContext);
  const [searchParams] = useSearchParams();
  const plateNo = searchParams.get('plate');
  const { isFullAccount } = usePerson();
  const { isSubmitting, setFieldTouched, setFieldValue, submitForm } =
    useFormikContext<QuoteJourneyFormData>();
  const referrer = useReferrer();
  const lazyPreload = useLazyPreload();
  useTheme(referrer);

  const handleResetRegSearch = () => {
    setFieldTouched('vehicle.registrationNumber', false);
    setFieldValue('vehicle.registrationNumber', '');
  };

  useEffect(() => {
    lazyPreload.preloadAuthRoutes();
  }, [lazyPreload]);

  useLayoutEffect(() => {
    if (plateNo) {
      setFieldValue('vehicle.registrationNumber', plateNo.toUpperCase());
      setTimeout(() => submitForm(), 0);
    }
  }, [plateNo, searchParams, setFieldValue, setIsLoading, submitForm]);

  return (
    <>
      {plateNo ? (
        <div
          className={clsx(
            'flex absolute top-0 left-0 z-30 flex-col items-center px-6 pt-6 w-full h-full text-center md:pt-12',
            'bg-gradient-to-br from-page-background-from to-page-background-to'
          )}
        >
          <LoadingSpinner className="my-16" />
        </div>
      ) : (
        <div className="flex flex-col justify-center w-full">
          <h1 className="mt-8 text-xl text-center md:mt-12 lg:mt-16 text-main-content-1">
            Let&apos;s get a new quote for your car
          </h1>
          <ContentContainer>
            <Card classNames="flex-col md:!bg-content-background-prominent !bg-transparent !p-0 md:!p-16 items-center my-8 lg:my-12  w-full">
              <Form>
                <VehicleRegSearch onClear={handleResetRegSearch} />
                <Button
                  id="new-quote-get-quote-for-vehicle"
                  testId="new-quote-get-quote-for-vehicle"
                  submit
                  fullWidth
                  classNames="mt-10"
                  loading={isSubmitting}
                  icon={{ name: 'arrow-right', position: 'right' }}
                  text="Get a new quote"
                />
              </Form>
              <AgreementInfo />
            </Card>
            {!isFullAccount && referrer.customerAccessEnabled && (
              <LoginCTA className="mb-24" />
            )}
          </ContentContainer>
        </div>
      )}
    </>
  );
};
