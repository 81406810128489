import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectRadioOption } from '@dayinsure/components';
import get from 'lodash.get';
import { FormRadioSelect } from '../../../../components';
import {
  baseOptionDtoToViewOptions,
  getFormFieldAccessPath,
  isYes,
} from '../../../../helpers';
import { FormValuesListField } from '../../../../components/FormsFields/FormValuesListField';
import { useDrivingQualificationsQuery } from '../../../../hooks';
import {
  YesNoDataEntry,
  QuoteJourneyFormData,
  YesNoAnswer,
  DriverFormData,
} from '../../../../types';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';

const getHasAdditionalQualificationsOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_has-additional-qualifications-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_has-additional-qualifications-option-no`,
  },
];
type QualificationsProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};

export const Qualifications = <T extends QuoteJourneyFormData | MtaJourneyFormData>({
  testId,
  formNameSpaceKey,
  additionalDriver,
}: QualificationsProps) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<T>();
  const currentDriver: DriverFormData = formNameSpaceKey
    ? get(values, formNameSpaceKey)
    : values;
  const {
    advancedDrivingQualifications: { hasAdvancedDrivingQualifications },
  } = currentDriver || {
    advancedDrivingQualifications: {},
  };
  const { data } = useDrivingQualificationsQuery(
    isYes(hasAdvancedDrivingQualifications?.id)
  );

  const drivingQualificationsOptions = useMemo(
    () => data?.map(baseOptionDtoToViewOptions(testId)) || [],
    [data, testId]
  );
  const provideAdvancedDrivingQualificationsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'advancedDrivingQualifications',
    'advancedDrivingQualifications',
  ]);
  const provideHasAdvancedDrivingQualificationsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'advancedDrivingQualifications',
    'hasAdvancedDrivingQualifications',
  ]);

  const handleHasAdditionalQualificationChange = useCallback(
    (option: SelectRadioOption) => {
      if (option.id === YesNoAnswer.NO) {
        setFieldValue(provideAdvancedDrivingQualificationsPath, []);
      } else {
        setFieldValue(provideAdvancedDrivingQualificationsPath, [null]);
      }
      setFieldTouched(provideAdvancedDrivingQualificationsPath, false);
    },
    [provideAdvancedDrivingQualificationsPath, setFieldTouched, setFieldValue]
  );

  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_qualifications-label`}
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
      >
        {additionalDriver
          ? 'Do they have any additional driving qualifications?'
          : 'Do you have any additional driving qualifications?'}
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          onChangeOptional={handleHasAdditionalQualificationChange}
          id={`${testId}_qualifications-radio`}
          testId={`${testId}_qualifications-radio`}
          name={provideHasAdvancedDrivingQualificationsPath}
          options={getHasAdditionalQualificationsOptions(testId)}
        />
      </div>

      {isYes(hasAdvancedDrivingQualifications?.id) && (
        <FormValuesListField
          name={provideAdvancedDrivingQualificationsPath}
          title="Qualification"
          options={drivingQualificationsOptions}
          testId={`${testId}_advanced-driving-qualifications`}
          iconName="cert"
        />
      )}
    </div>
  );
};
