import { useRef } from 'react';
import { FieldError } from '@dayinsure/components';
import Reaptcha from 'reaptcha';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { QuoteJourneyFormData } from '../../../../../types';
import { useFocusOnError } from '../../../../../hooks';
import { SignupFormData } from '../../../../../forms';
import { env } from '../../../../../configuration/env';

type ReaptchaInputProps = {
  className?: string;
  testId: string;
};

export const ReaptchaInput = ({ className, testId }: ReaptchaInputProps) => {
  const { errors, touched, setFieldValue } = useFormikContext<
    QuoteJourneyFormData | SignupFormData
  >();
  const reaptchaRef = useRef<HTMLDivElement>(null);
  useFocusOnError({ fieldRef: reaptchaRef, name: 'signUp.reaptcha' });
  const handleSetReaptcha = (token: string) => setFieldValue('signUp.reaptcha', token);

  return (
    <div
      className={clsx('flex flex-col items-center mt-12 w-full text-center', className)}
      data-testid={`${testId}_reaptcha`}
    >
      <div ref={reaptchaRef} className="inline-flex flex-col">
        <Reaptcha
          sitekey={env.config.RECAPTCHA_KEY || ''}
          onVerify={handleSetReaptcha}
          className="-mx-16 scale-75 origin-center xs:scale-95 sm:mx-0 sm:scale-100"
        />
        {touched.signUp?.reaptcha && (
          <FieldError
            className="self-end"
            error={errors.signUp?.reaptcha || ''}
            id={`${testId}_reaptcha_error`}
            testId={`${testId}_reaptcha_error`}
          />
        )}
      </div>
    </div>
  );
};
