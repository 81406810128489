import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  ApiError,
  CancelablePromise,
  Dayinsure as DayInsureAPI,
  OpenAPI,
  ResumeOrderRequestDto,
  ResumeOrderResponseDto,
} from '../../../api/v1';
import { query } from '../../queries';

export const useResumePaymentMutation = (id: string) => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);

  const resumePayment = (
    requestBody: ResumeOrderRequestDto
  ): CancelablePromise<ResumeOrderResponseDto> => {
    if (!id) {
      throw new Error('No order ID');
    }
    const api = new DayInsureAPI(OpenAPI);
    return api.order.postOrderResume(id, requestBody);
  };

  return useMutation(resumePayment, {
    onSuccess: (data: ResumeOrderResponseDto) => {
      queryClient.setQueryData<ResumeOrderResponseDto>(
        query.resumeOrder(data?.resultCode || ''),
        () => {
          if (data?.errorCode) {
            throw new ApiError(
              {
                statusText: `${data.errorCode}`,
                status: 404,
                body: {
                  error: (data as any)?.errorMessage || 'error',
                },
                url: '',
                ok: true,
              },
              `${data.errorCode}`
            );
          }
          return data;
        }
      );
    },
    onError: (err: ApiError) => {
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
      }
    },
  });
};
