import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InformationDialog } from '@dayinsure/components/src/components/Feedback/InformationDialog';
import { useFormikContext } from 'formik';
import { Card } from '@dayinsure/components';
import { AddOnCheckboxCard } from './AddOnCheckboxCard';
import { AddOnCheckboxOption } from './types';
import { useGetProductSchemeAddons } from '../../../../../hooks';
import { AddOnDto } from '../../../../../api/v2';
import { AddOnDescription } from '../AddOnDescription';
import { AddOnType } from '../AddOns.types';
import { AddOn, QuoteJourneyFormData } from '../../../../../types';
import { isYes, toYesNoDataEntry } from '../../../../../helpers';
import { BREAKDOWN_NOT_NEEDED_ID } from '../AddOns.utils';

/** This is a styled checkbox group */
export type AddOnCheckboxSelectProps = {
  /** The options available in the checkbox group */
  value: AddOn[];
  /** The event which will trigger when an checkbox option is selected, return all options  */
  onChange?: (values: AddOn[]) => void;
  /** The event which will trigger when an checkbox option is selected, return selected option */
  onClick?: (value: AddOn) => void;
  /** The event which will trigger when the checkboxes are blurred */
  onBlur?: () => void;
  /** Disabled state */
  disabled?: boolean;
  /** The number of columns to split into. Will be based on breakpoint if undefined */
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
  /** Id used for testing */
  testId?: string;
};

export const AddOnCheckboxSelect = ({
  testId,
  value,
  onChange,
  onClick,
  onBlur,
  disabled,
  cols,
}: AddOnCheckboxSelectProps) => {
  const [showNCBInfo, setShowNCBInfo] = useState(false);
  const { data } = useGetProductSchemeAddons();
  const {
    setFieldValue,
    values: {
      protectNoClaimsDiscount,
      cover: { optionalAddOnsValidation },
    },
  } = useFormikContext<QuoteJourneyFormData>();
  const filterAddonSchema = useCallback(
    (addOnName: string | undefined) =>
      data?.find((addOn: AddOnDto) => {
        const name = addOnName?.toLowerCase();
        const identifier = addOn?.identifier?.toLowerCase();
        return name === identifier;
      }),
    [data]
  );
  const handleChange = (option: AddOn) => {
    if (onBlur) {
      onBlur();
    }

    if (onChange) {
      onChange(
        value.map(val => {
          if (val.id === option.id) {
            return {
              id: val.id,
              name: val.name,
              checked: option.checked,
              price: val.price,
              priceMonthly: val.priceMonthly,
              selectedPlan: val.selectedPlan,
              isOptionalBreakdownAddOn: val.isOptionalBreakdownAddOn,
            };
          }
          return val;
        })
      );
    }

    if (onClick) {
      onClick(option);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Don't blur when focus changes between checkboxes
    if (onBlur && !e.currentTarget.contains(e.relatedTarget)) {
      onBlur();
    }
  };

  const showNBCMoreInfo = () => {
    setShowNCBInfo(true);
  };

  const hideNBCMoreInfo = () => {
    setShowNCBInfo(false);
  };

  const optionalAddonsInit = useMemo(() => {
    return value.map((val: AddOnCheckboxOption, i: number) => {
      if (val?.id === AddOnType.PROTECTED_NCB) {
        if (isYes(protectNoClaimsDiscount?.id)) {
          return toYesNoDataEntry(true);
        }
        return toYesNoDataEntry(false);
      }

      if (val.id === BREAKDOWN_NOT_NEEDED_ID && val.checked) {
        return toYesNoDataEntry(true);
      }

      if (optionalAddOnsValidation[i]) {
        return toYesNoDataEntry(val.checked);
      }
      if (val.checked) {
        return toYesNoDataEntry(val.checked);
      }
      return null;
    });
  }, [optionalAddOnsValidation, protectNoClaimsDiscount?.id, value]);

  useEffect(() => {
    setFieldValue('cover.optionalAddOnsValidation', optionalAddonsInit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        'grid relative gap-6',
        { 'grid-cols-1 md:grid-cols-2': !cols },
        { 'grid-cols-1': cols?.mobile === 1 },
        { 'grid-cols-2': cols?.mobile === 2 },
        { 'sm:grid-cols-1': cols?.tablet === 1 },
        { 'sm:grid-cols-2': cols?.tablet === 2 },
        { 'lg:grid-cols-1': cols?.desktop === 1 },
        { 'lg:grid-cols-2': cols?.desktop === 2 }
      )}
      onBlur={handleBlur}
      data-testid={testId}
    >
      <Card
        testId={`${testId}_info_card`}
        paddingLevel="small"
        classNames="mb-6 lg:mb-10"
      >
        <span>Get extra peace of mind by selecting optional add-ons.</span>
      </Card>
      {value.map((val: AddOnCheckboxOption, i: number) => (
        <AddOnCheckboxCard
          key={`k-${val.id}`}
          addOnId={val.id}
          addOnValue={val}
          addOnSchema={filterAddonSchema(val?.identifier)}
          onChange={handleChange}
          name={`cover.optionalAddOnsValidation[${i}]`}
          clickFormMoreInfo={
            val?.id === AddOnType.PROTECTED_NCB ? showNBCMoreInfo : undefined
          }
          disabled={disabled}
          testId={`addon_checkbox_card_${i}`}
        />
      ))}
      <InformationDialog
        open={showNCBInfo}
        title="Protected No Claims Bonus"
        onClose={hideNBCMoreInfo}
        testId={`${testId}_add-on`}
      >
        <AddOnDescription addOnType={AddOnType.PROTECTED_NCB} testId={testId || ''} />
      </InformationDialog>
    </div>
  );
};
