import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { GlobalErrorContext } from '@dayinsure/shared';
import { query } from '../queryKeys';
import {
  Dayinsure as DayinsureAPIv1,
  OpenAPI,
  ApiError,
  GetMotorQuoteResponseDto,
} from '../../../api/v1';
import { isApiErrorV1 } from '../../../helpers';

export const useQuoteFetchQuery = () => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getQuote = (quoteId: string) => () => {
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorQuote.getMotorQuote(quoteId);
  };

  return async (quoteId: string) => {
    try {
      const data = await queryClient.fetchQuery<GetMotorQuoteResponseDto, ApiError>(
        query.quoteDetails(quoteId),
        getQuote(quoteId)
      );

      return data;
    } catch (e) {
      if (isApiErrorV1(e) && !(e?.status === 401)) {
        setGlobalError(e);
      }

      return null;
    }
  };
};
