import clsx from 'clsx';
import { Card, FieldError, FormattedPrice, Icon } from '@dayinsure/components';
import { BreakdownOption } from './BreakdownOption';
import { isAnnualPaymentPlan } from '../../../../../../helpers';
import { AddOn } from '../../../../../../types';

export type BreakdownOptionsProps = {
  /** The options available in the radiogroup */
  options: AddOn[];
  /** The current value. Must match one of the radio options. Can be undefined */
  value: AddOn | undefined;
  /** The event which will trigger when an radio option is selected */
  onChange: (option: AddOn) => void;
  /** Error state */
  error?: string;
  /** Disabled state */
  disabled?: boolean;
  /**  Whether to use the primary or secondary colour scheme */
  variant?: 'primary' | 'secondary';
  /** The number of columns to split into. Will be based on breakpoint if undefined */
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
  /** id used for testing */
  testId?: string;
};

export const BreakdownOptions = ({
  options,
  value,
  onChange,
  error,
  disabled,
  testId,
  variant = 'primary',
  cols,
}: BreakdownOptionsProps) => {
  return (
    <div className="w-full" data-testid={testId}>
      <div
        className={clsx(
          'grid relative gap-6',
          { 'grid-cols-1 md:grid-cols-2': !cols },
          { 'grid-cols-1': cols?.mobile === 1 },
          { 'grid-cols-2': cols?.mobile === 2 },
          { 'sm:grid-cols-1': cols?.tablet === 1 },
          { 'sm:grid-cols-2': cols?.tablet === 2 },
          { 'lg:grid-cols-1': cols?.desktop === 1 },
          { 'lg:grid-cols-2': cols?.desktop === 2 }
        )}
      >
        {options.map((option, index) => (
          <Card
            key={option.id}
            testId={`${testId}_breakdown-addon-${option.id}`}
            classNames="rounded-lg bg-content-background p-4 md:p-6 lg:p-8 flex flex-col !h-full"
          >
            <h4 className="mb-6 h-full font-lato text-sm font-bold">{option.name}</h4>

            <ul className="mb-6 text-sm list-none">
              {option?.included &&
                option?.included.map((include: string) => (
                  <li key={include} className={clsx('flex items-center leading-5')}>
                    <Icon className="mr-1" name="tick" size="0.75rem" />
                    <p>{include}</p>
                  </li>
                ))}
              {option?.notIncluded &&
                option.notIncluded.map((notInclude: string) => (
                  <li
                    key={notInclude}
                    className={clsx('flex items-center leading-5 text-main-content-3')}
                  >
                    <Icon className="mr-1" name="cross" size="0.75rem" />
                    <p>{notInclude}</p>
                  </li>
                ))}
            </ul>

            <div className="mb-6">
              {isAnnualPaymentPlan(option.selectedPlan) ? (
                <p className="font-raleway">
                  <FormattedPrice price={option.price} size="LG" />
                  <span className="text-sm"> /year</span>
                </p>
              ) : (
                <div className="flex flex-col font-raleway">
                  <p>
                    <FormattedPrice price={option.priceMonthly} size="LG" />
                    <span className="text-sm"> /month</span>
                  </p>
                  <p className="text-sm">{`£${option.price} /year`}</p>
                </div>
              )}
            </div>

            <div className="relative justify-self-end form-field-spacing">
              <BreakdownOption
                key={option.id}
                option={option}
                index={index}
                checked={value?.id === option.id}
                disabled={disabled}
                variant={variant}
                error={!!error}
                onClick={onChange}
              />
              {error && (
                <FieldError
                  testId={`${option.id}_error`}
                  id={`${option.id}_error`}
                  error={error}
                  className="absolute right-0 mt-2"
                />
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
