import clsx from 'clsx';
import { ChangeEvent, HTMLProps } from 'react';
import gbflag from '../../../assets/image_gbflag.svg';
import { onEnterKey } from '../../../types/events';
import Icon from '../../DataDisplay/Icon/Icon';
import FieldError from '../../Feedback/FieldError/FieldError';

export type VehicleRegProps = {
  /* Error message string */
  error?: string;
  /** ID of the input field. Used to match with label */
  id: string;
  /** Name for the field. Used to link up to formik */
  name: string;
  /* Placeholder when value is empty */
  placeholder: string;
  /* Boolean which means that field has been changed */
  touched?: boolean;
  /* Clearing value function */
  onClear: VoidFunction;
} & HTMLProps<HTMLInputElement>;

export const VehicleReg = ({
  error,
  id,
  name,
  placeholder,
  touched,
  value,
  onChange,
  onClear,
  ...inputProps
}: VehicleRegProps): JSX.Element => {
  const hasError = !!error && touched;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toUpperCase().replace(' ', '');
    onChange?.(e);
  };

  return (
    <div className="relative">
      <div className="flex">
        <div
          className={clsx(
            `flex flex-col justify-center items-center w-10 h-16 bg-[#003287] rounded-l-lg lg:w-12 lg:h-[4.5rem]`,
            {
              'border-y-2 border-l-2 border-error-border': hasError,
              'box-border border-y-2 border-l-2 border-reg-plate': !hasError,
            }
          )}
        >
          <img src={gbflag} alt="GB" width="16px" height="12px" />
          <p className="pt-1 text-sm text-white lg:text-md">GB</p>
        </div>
        <div
          className={clsx(
            `flex overflow-hidden relative items-center w-full h-16 bg-[#FFC850] rounded-r-lg lg:h-[4.5rem]`,
            {
              'border-y-2 border-r-2 border-error-border': hasError,
              'box-border border-y-2 border-r-2 border-reg-plate': !hasError,
            }
          )}
        >
          <input
            {...inputProps}
            name={name}
            value={value}
            onChange={handleChange}
            id={id}
            data-testid={id}
            type="text"
            maxLength={8}
            autoComplete="off"
            className="flex focus:placeholder:invisible items-center mr-8 w-full text-[1.25rem] font-semibold text-center bg-transparent focus:outline-none lg:text-lg  placeholder:text-reg-plate-text text-reg-plate-text"
            placeholder={placeholder}
            aria-invalid={hasError}
            aria-errormessage={`${id}-error`}
          />
          {!!value && (
            <button
              className="flex absolute right-6 z-10 p-2"
              aria-label="Clear registration"
              type="button"
              onKeyDown={onEnterKey(onClear)}
              onMouseDown={onClear}
            >
              <Icon className="text-reg-plate-text" name="cross" />
            </button>
          )}
        </div>
      </div>

      {touched && error && (
        <FieldError
          id={`${id}-error`}
          data-testid="registrationError"
          error={error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
