import type { OpenAPIConfig } from '../core/OpenAPI';

import { AddressService } from './services/AddressService';
import { BaseAPI } from '../core/BaseAPI';

export class Dayinsure extends BaseAPI {
  readonly address: AddressService;

  constructor(config?: Partial<OpenAPIConfig>) {
    super(config);

    this.address = new AddressService(this.request);
  }
}
