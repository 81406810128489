import { Card } from '@dayinsure/components';
import clsx from 'clsx';
import { PaymentPlanDto } from '../../../../api/v1';
import { UsualPaymentFrequency } from '../../../../types';
import {
  formatNegativePrice,
  getCurrentPaymentPlan,
  toFixedString,
} from '../../../../helpers';

type NewMonthlyPaymentsProps = {
  testId: string;
  paymentPlans: PaymentPlanDto[] | undefined | null;
  usualPaymentFrequency: UsualPaymentFrequency;
  smallSize?: boolean;
  classNames?: string;
};
export const NewMonthlyPayments = ({
  testId,
  smallSize = false,
  usualPaymentFrequency,
  classNames,
  paymentPlans,
}: NewMonthlyPaymentsProps) => {
  const currentPlan = getCurrentPaymentPlan(usualPaymentFrequency, paymentPlans);
  const installmentDetails = currentPlan?.installmentDetails;
  if (!installmentDetails) {
    return null;
  }
  const style = `flex justify-between font-lato ${
    smallSize ? 'text-sm leading-5' : 'leading-[18px]'
  }`;
  return (
    <Card paddingLevel="small" classNames={clsx(`mt-6 mb-8 lg:mt-8 ${classNames}`)}>
      <h2 className="mb-8 text-lg">New monthly payments</h2>
      <div className={style} data-testid={`${testId}_amount-to-be-financed`}>
        <span>Amount to be financed</span>
        <span className="font-bold">
          {formatNegativePrice(Number(installmentDetails?.financeAmount?.amount))}
        </span>
      </div>
      {installmentDetails?.interestRate && (
        <div className={style} data-testid={`${testId}_interest-rate`}>
          <span>Interest rate</span>
          <span className="font-bold">{installmentDetails?.interestRate}%</span>
        </div>
      )}
      {installmentDetails?.aprFromProvider && (
        <div className={style} data-testid={`${testId}_representative-APR`}>
          <span>Representative APR</span>
          <span className="font-bold">{installmentDetails?.aprFromProvider}%</span>
        </div>
      )}
      <div className={style} data-testid={`${testId}_monthly-payments-remaining`}>
        <span>{installmentDetails?.numberOfInstallments} monthly payments remaining</span>
        <span className="font-bold">
          £{toFixedString(installmentDetails?.monthlyCost?.amount)}
        </span>
      </div>
      <div className={style} data-testid={`${testId}_total-amount-payable-for-change`}>
        <span>Total amount payable for change</span>
        <span className="font-bold">
          £{toFixedString(currentPlan?.installmentDetails?.total?.amount)}
        </span>
      </div>
    </Card>
  );
};
