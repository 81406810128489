import { Card, Icon } from '@dayinsure/components';

type PolicyInfoContactCardsProps = {
  displayRAC: boolean;
  testId: string;
  className?: string;
};

export const ContactCards = ({
  displayRAC = false,
  testId,
  className,
}: PolicyInfoContactCardsProps) => (
  <section className={className}>
    <Card
      classNames="flex items-center my-8"
      paddingLevel="small"
      testId={`${testId}_mulsanne-claims`}
    >
      <Icon className="text-main-content-1" size="2rem" name="phone" />
      <div className="ml-6 text-main-content-1">
        Should you need to make a claim on your policy, please call Mulsanne Insurance on
        <a href="tel:03332076598" className="ml-2 font-bold whitespace-nowrap text-link">
          0333 207 6598
        </a>
        . All claims are handled by Mulsanne Insurance.
      </div>
    </Card>

    {displayRAC && (
      <Card
        classNames="flex items-center"
        paddingLevel="small"
        testId={`${testId}_RAC-phone`}
      >
        <Icon className="text-main-content-1" size="2rem" name="phone" />
        <div className="ml-6 text-main-content-1">
          If you have purchased breakdown cover and are unfortunate enough to break down
          please call RAC on
          <a
            href="tel:03301590241"
            className="ml-2 font-bold whitespace-nowrap text-link"
          >
            0330 159 0303
          </a>
          .
        </div>
      </Card>
    )}
  </section>
);
