import type { DeeplinkMotorQuoteSuccessDto } from '../models/DeeplinkMotorQuoteSuccessDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PcwDeeplinkService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postPcwDeeplinkValidateOtp(formData?: {
    otp?: string;
  }): CancelablePromise<DeeplinkMotorQuoteSuccessDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/pcw-deeplink/validate-otp',
      formData,
      mediaType: 'multipart/form-data',
    });
  }
}
