import type { CreateMotorQuoteRequestDto } from '../models/CreateMotorQuoteRequestDto';
import type { CreateMotorQuoteResponseDto } from '../models/CreateMotorQuoteResponseDto';
import type { DocumentDto } from '../models/DocumentDto';
import type { GetMotorQuoteResponseDto } from '../models/GetMotorQuoteResponseDto';
import type { UpdateMotorQuoteRequestDto } from '../models/UpdateMotorQuoteRequestDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class MotorQuoteService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postMotorQuote(
    requestBody?: CreateMotorQuoteRequestDto
  ): CancelablePromise<CreateMotorQuoteResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-quote',
      body: requestBody,
    });
  }

  getMotorQuote(id: string): CancelablePromise<GetMotorQuoteResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-quote/{id}',
      path: {
        id,
      },
    });
  }

  putMotorQuote(
    id: string,
    requestBody?: UpdateMotorQuoteRequestDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/motor-quote/{id}',
      path: {
        id,
      },
      body: requestBody,
    });
  }

  getMotorQuoteDocuments(id: string): CancelablePromise<DocumentDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-quote/{id}/documents',
      path: {
        id,
      },
    });
  }
}
