import { Button, Card, Icon } from '@dayinsure/components';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { MotorPolicyDocumentDto } from '../../api/v1';
import { usePolicyDocumentsQuery } from '../../hooks';

type PolicyDocumentsProps = {
  policyId: string;
  className?: string;
  testId?: string;
  noDownloadInfo?: boolean;
  version?: number;
};

const TEN_MINUTES = 10 * 60 * 1000;

export const PolicyDocuments = ({
  testId,
  policyId,
  className,
  noDownloadInfo,
  version,
}: PolicyDocumentsProps) => {
  const [documentsValid, setDocumentsValid] = useState<boolean>(false);
  const {
    data: documents,
    refetch,
    isRefetching,
    isLoading,
  } = usePolicyDocumentsQuery(policyId, version);

  const setDocumentsAreInvalid = () =>
    setTimeout(() => {
      setDocumentsValid(false);
    }, TEN_MINUTES);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (documents) {
      setDocumentsValid(true);
      timeoutRef.current = setDocumentsAreInvalid();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [documents]);

  const handleButtonClick = () =>
    refetch().then(() => {
      setDocumentsValid(true);
      timeoutRef.current = setDocumentsAreInvalid();
    });

  return (
    <section className={className}>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent"
        testId={`${testId}_policy-documents-card`}
      >
        <h2 className={clsx('font-raleway text-lg', { 'mb-4': documentsValid })}>
          Documents
        </h2>
        {documentsValid ? (
          <>
            {!documents?.length && <div>No documents available</div>}
            <div data-testid={`${testId}_policy-documents`}>
              {documents?.map(({ description, url }: MotorPolicyDocumentDto, index) => (
                <a
                  href={url || ''}
                  target="_blank"
                  key={`doc-${index.toString()}`}
                  rel="noreferrer noopener"
                >
                  <div className="flex items-center mb-2 text-sm text-main-content-2">
                    <Icon name="download" className="text-link" />
                    <span className="ml-2 text-base font-semibold text-link">
                      {description}
                    </span>
                  </div>
                </a>
              ))}
            </div>
            {!noDownloadInfo && (
              <section
                className="mt-6"
                data-testid={`${testId}_policy-documents_send-documents-by-post`}
              >
                <p className=" font-lato text-sm leading-5">
                  Your documents can be downloaded above. If you need them sending in the
                  post, then please email{' '}
                  <a
                    className="font-semibold underline text-link"
                    href="mailto:documents@everydayinsure.co.uk"
                  >
                    documents@everydayinsure.co.uk
                  </a>
                </p>
              </section>
            )}
          </>
        ) : (
          <Button
            variant="secondary"
            classNames="w-full sm:w-fit self-endi put shrink-0 mt-6"
            id={`${testId}_policy-documents_view-documents-button`}
            data-testid={`${testId}_policy-documents_view-documents-button`}
            text="View documents"
            onClick={handleButtonClick}
            loading={isLoading || isRefetching}
          />
        )}
      </Card>
    </section>
  );
};
