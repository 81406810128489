import { Footer as FooterUI } from '@dayinsure/components';
import { env } from '../../../configuration/env';

/*
  TODO: Add documents support as in
  src/components/Footer/Footer.tsx
  after API is ready
*/

const footerWording =
  '© 2024 Dayinsure.com Limited. Everydayinsure™ is a registered trademark of Dayinsure.com Limited. Everydayinsure Limited is an Appointed Representative and a wholly owned subsidiary of Dayinsure.com Limited, who are Authorised and Regulated by the Financial Conduct Authority No: 304010. Dayinsure.com Limited is a company incorporated in England and Wales No: 04996289 and the registered office is at Mara House, Nantwich Road, Tarporley, England, CW6 9UY. Everydayinsure is a company incorporated in England and Wales No. 15202873. Our registered office is at Mara House, Nantwich Road, Tarporley, England, CW6 9UY. Our FCA number is 1016756 and we are an intermediary, not an insurer.';

const Footer = (): JSX.Element => {
  const documentPrefix = env.config.DOCUMENT_PREFIX || '';
  return (
    <FooterUI footerLegalWording={footerWording}>
      <FooterUI.Link
        id="footer-terms-of-business-link"
        href={`${documentPrefix}resources/DocumentStore/Annual/Everydayinsure/TOBA/EDI%20TOBA%20v1.0.pdf`}
      >
        Terms of business
      </FooterUI.Link>
      <FooterUI.Link
        id="footer-privacy-policy-link"
        href={`${documentPrefix}resources/DocumentStore/Annual/Everydayinsure/PrivacyPolicy/EDI%20Privacy%20Policy%20v1.0.pdf`}
      >
        Privacy Policy
      </FooterUI.Link>
      <FooterUI.Link
        id="footer-cookie-policy-link"
        href={`${documentPrefix}resources/DocumentStore/Annual/Everydayinsure/CookiePolicy/EDI%20Cookie%20Policy%20v1.0.pdf`}
      >
        Cookie Policy
      </FooterUI.Link>
    </FooterUI>
  );
};

export { Footer };
