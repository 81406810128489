import { CostDto, PaymentPlanDto } from '../../../api/v1';

export const getDisplayPrice = (
  isPlanAnnual: boolean,
  currentPlan?: PaymentPlanDto,
  priceWithAddons?: CostDto
) =>
  isPlanAnnual
    ? priceWithAddons?.amount
    : currentPlan?.installmentDetails?.monthlyCost?.amount;

export const getDisplayPriceInBreakdown = (
  isPlanAnnual: boolean,
  currentPlan?: PaymentPlanDto,
  priceWithAddons?: CostDto
) =>
  isPlanAnnual ? priceWithAddons?.amount : currentPlan?.installmentDetails?.total?.amount;
