import type { MarketingConsentsRequestDto } from '../models/MarketingConsentsRequestDto';
import type { MarketingConsentsResponseDto } from '../models/MarketingConsentsResponseDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class MarketingConsentService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postMarketingConsentCreate(
    requestBody?: MarketingConsentsRequestDto
  ): CancelablePromise<MarketingConsentsResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/marketing-consent/create',
      body: requestBody,
    });
  }

  getMarketingConsent(): CancelablePromise<MarketingConsentsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/marketing-consent',
    });
  }
}
