import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { SelectRadioOption } from '@dayinsure/components';
import get from 'lodash.get';
import {
  baseOptionDtoToViewOptions,
  getFormFieldAccessPath,
  isYes,
} from '../../../../helpers';
import { FormListBox, FormRadioSelect, FormFieldBox } from '../../../../components';
import {
  DriverFormData,
  QuoteJourneyFormData,
  YesNoDataEntry,
  YesNoAnswer,
} from '../../../../types';
import { useOtherVehicleUseQuery } from '../../../../hooks/queries/useOtherVehicleUseQuery';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';

type DriveAnotherCarProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};

export const getUseAnotherCarOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_use-another-car-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_use-another-car-option-no`,
  },
];

export const DriveAnotherCar = <T extends QuoteJourneyFormData | MtaJourneyFormData>({
  testId,
  formNameSpaceKey,
  additionalDriver,
}: DriveAnotherCarProps) => {
  const { setFieldTouched, setFieldError, setFieldValue, values } = useFormikContext<T>();

  const currentDriver: DriverFormData = formNameSpaceKey
    ? get(values, formNameSpaceKey)
    : values;

  const { data } = useOtherVehicleUseQuery(
    isYes(currentDriver?.otherVehicleUse?.otherVehicleUse?.id)
  );
  const usageOptions = useMemo(
    () => data?.map(baseOptionDtoToViewOptions(`${testId}_licence-type`)) ?? [],
    [data, testId]
  );

  const provideOtherVehicleUsePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'otherVehicleUse',
    'otherVehicleUse',
  ]);

  const provideOtherVehicleUsCodePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'otherVehicleUse',
    'otherVehicleUseCode',
  ]);

  const onOtherVehicleUseChange = (option: SelectRadioOption) => {
    if (option?.id === YesNoAnswer.NO) {
      setFieldValue(provideOtherVehicleUsCodePath, null);
      setFieldError(provideOtherVehicleUsCodePath, undefined);
    }
    setFieldTouched(provideOtherVehicleUsCodePath, false);
  };

  return (
    <FormFieldBox>
      <h3
        data-testid={`${testId}_use-another-car-label`}
        className="w-full font-raleway font-normal form-subtitle-compact"
      >
        {additionalDriver
          ? 'Do they own or drive another vehicle?'
          : 'Do you own or drive another vehicle?'}
      </h3>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          onChangeOptional={onOtherVehicleUseChange}
          id={`${testId}_use-another-car-radio`}
          testId={`${testId}_use-another-car-radio`}
          name={provideOtherVehicleUsePath}
          options={getUseAnotherCarOptions(testId)}
        />
      </div>
      {isYes(currentDriver?.otherVehicleUse?.otherVehicleUse?.id) && (
        <div className="mb-4 md:mb-8">
          <FormListBox
            unselectedMessage="Please select"
            name={provideOtherVehicleUsCodePath}
            options={usageOptions}
            id={`${testId}_use-another-car-select`}
            testId={`${testId}_use-another-car-select`}
            icon="cog"
            label="Use of another vehicle"
          />
        </div>
      )}
    </FormFieldBox>
  );
};
