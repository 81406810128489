import type { ConfirmAccountEmailRequest } from '../models/ConfirmAccountEmailRequest';
import type { ConfirmAccountResponse } from '../models/ConfirmAccountResponse';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { IAPIResult } from '../models/IAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PersonRequest } from '../models/PersonRequest';
import type { PersonResponse } from '../models/PersonResponse';
import type { PersonResponseV2 } from '../models/PersonResponseV2';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequestV2 } from '../models/UpdatePersonRequestV2';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PersonService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getApiV2Person(id: string): CancelablePromise<PersonResponseV2> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{id}',
      path: {
        id,
      },
    });
  }

  putApiV2Person(
    id: string,
    requestBody?: UpdatePersonRequestV2
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/person/{id}',
      path: {
        id,
      },
      body: requestBody,
    });
  }

  postApiV2Person(requestBody?: PersonRequest): CancelablePromise<PersonResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person',
      body: requestBody,
    });
  }

  postApiV2PersonSendPasswordReset(
    email: string,
    requestBody?: SendPasswordResetRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{email}/send-password-reset',
      path: {
        email,
      },
      body: requestBody,
    });
  }

  postApiV2PersonConfirmPasswordReset(
    personId: string,
    requestBody?: ConfirmPasswordResetRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/confirm-password-reset',
      path: {
        personId,
      },
      body: requestBody,
    });
  }

  postApiV2PersonSendEmailVerification(
    email: string,
    requestBody?: SendVerificationEmailRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{email}/send-email-verification',
      path: {
        email,
      },
      body: requestBody,
    });
  }

  postApiV2PersonVerifyEmailAddress(
    personId: string,
    requestBody?: VerifyEmailAddressRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/verify-email-address',
      path: {
        personId,
      },
      body: requestBody,
    });
  }

  postApiV2PersonConfirmAccountEmail(
    personId: string,
    requestBody?: ConfirmAccountEmailRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/confirm-account-email',
      path: {
        personId,
      },
      body: requestBody,
    });
  }

  getApiV2PersonConfirmAccountExists(
    email: string
  ): CancelablePromise<ConfirmAccountResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{email}/confirm-account-exists',
      path: {
        email,
      },
    });
  }

  putApiV2PersonChangePassword(
    personId: string,
    requestBody?: PasswordChangeRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/person/{personId}/change-password',
      path: {
        personId,
      },
      body: requestBody,
    });
  }
}
