import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useGetProductSchemeAddons, useQuoteQuery } from '../../../../hooks';
import {
  getCoverStartDateReviewCard,
  getCoverEndDateReviewCard,
  getDriversReviewCard,
  getCoverReviewCard,
  getPaymentsReviewCard,
  getCarEditLink,
  getDriversEditLink,
  getCoverStartEditLink,
} from './QuoteDetailsReview.utils';
import { ReviewPageSummaryCard } from '../ReviewPageSummaryCard';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { CarSummaryCard } from '../CarSummryCard';
import { mapAddonsNamesAndDescriptions } from '../../../../helpers/quote/mapAddonsNamesAndDescriptions';

export type QuoteDetailsReviewProps = {
  testId: string;
  mta?: boolean;
};

export const QuoteDetailsReview = ({ testId, mta }: QuoteDetailsReviewProps) => {
  const { data: addOnsMeta } = useGetProductSchemeAddons();
  const { quoteId } = useParams<{ quoteId: string }>();
  const quote = useQuoteQuery(quoteId);
  const addonsWithMeta = mapAddonsNamesAndDescriptions(quote?.data?.addOns, addOnsMeta);
  const policyHolderAddress = quote?.data?.proposer?.address;
  const proposerDriver = quote?.data?.drivers?.find(driver => driver.isProposer);
  const {
    values: { usualPaymentFrequency, policyChange },
  } = useFormikContext<MtaJourneyFormData>();

  const startDateUtc = mta
    ? quote.data?.effectiveDateTimeUtc
    : quote.data?.cover?.startDateTimeUtc;

  const coverStartReviewCard = getCoverStartDateReviewCard(
    startDateUtc,
    mta,
    getCoverStartEditLink(policyChange, mta)
  );
  const coverEndReviewCard = getCoverEndDateReviewCard(quote.data?.cover);
  const driversReviewCard = getDriversReviewCard(
    quote.data?.drivers,
    getDriversEditLink(policyChange, mta),
    policyHolderAddress
  );
  const coverReviewCard = getCoverReviewCard(
    {
      cover: quote.data?.cover,
      excesses: quote.data?.excesses,
      addOns: addonsWithMeta,
      product: quote.data?.product,
      noClaimsHistory: proposerDriver?.noClaimsHistory,
    },
    mta
  );

  const paymentsReviewCard = getPaymentsReviewCard(
    quote.data?.paymentPlans,
    usualPaymentFrequency,
    mta
  );
  const cardsData = [
    coverStartReviewCard,
    coverEndReviewCard,
    driversReviewCard,
    coverReviewCard,
    paymentsReviewCard,
  ];

  return (
    <div data-testid={`${testId}_cards`} className="flex flex-wrap gap-6 items-stretch">
      <CarSummaryCard
        editLink={getCarEditLink(policyChange, mta)}
        vehicle={quote.data?.vehicle}
        testId={`${testId}_car-info`}
      />
      {cardsData.map(
        card =>
          card && (
            <ReviewPageSummaryCard
              key={card.testId}
              {...card}
              testId={`${testId}_${card.testId}`}
            />
          )
      )}
    </div>
  );
};
