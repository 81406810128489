import { PaymentTypeRadioOption } from '@dayinsure/components';
import { CostDto, InsurancePriceDto, PaymentPlanDto } from '../../../../api/v1';
import {
  formatNegativePrice,
  getPaymentPlanNameFromCode,
  isAnnualPaymentPlan,
  isDefined,
  isMonthlyPaymentPlan,
  toFixedString,
} from '../../../../helpers';
import { UsualPaymentFrequency } from '../../../../types';
import { PaymentTypesEnum } from '../../../../constants/payments';

export const getPaymentTypeOptions = (
  paymentPlans: PaymentPlanDto[],
  testId: string,
  usualPaymentFrequency: UsualPaymentFrequency,
  insurencePrice?: InsurancePriceDto,
  totalPriceIncAddOns?: CostDto,
  isMta?: boolean,
  isRefund?: boolean
): PaymentTypeRadioOption[] => {
  const totalPrice = totalPriceIncAddOns
    ? totalPriceIncAddOns?.amount
    : insurencePrice?.total?.amount;

  const monthlyPlan = paymentPlans.find(plan => isMonthlyPaymentPlan(plan.type?.code));
  const isMonthlyPlanValid =
    Object.keys(monthlyPlan?.installmentDetails || {}).length !== 0;

  const options = paymentPlans.map(plan => {
    if (isMta && isAnnualPaymentPlan(plan.type?.code)) {
      return {
        id: PaymentTypesEnum.ANNUAL,
        name: getPaymentPlanNameFromCode(plan.type?.code, isMta, isRefund),
        testId: `${testId}_payment-type_option-annually`,
        price: toFixedString(totalPrice),
        isAnnual: true,
        isMta,
        isRefund,
      };
    }

    if (!isMta && isAnnualPaymentPlan(plan.type?.code)) {
      return {
        id: PaymentTypesEnum.ANNUAL,
        name: getPaymentPlanNameFromCode(plan.type?.code),
        testId: `${testId}_payment-type_option-annually`,
        price: toFixedString(totalPrice),
        isAnnual: true,
        isMta,
        isRefund,
      };
    }

    if (isMonthlyPaymentPlan(plan.type?.code) && isMonthlyPlanValid) {
      return {
        id: PaymentTypesEnum.MONTHLY,
        name: getPaymentPlanNameFromCode(plan.type?.code, isMta, isRefund),
        testId: `${testId}_payment-type_option-monthly`,
        price: toFixedString(plan.installmentDetails?.monthlyCost?.amount),
        details: {
          initialPayment: `£${toFixedString(
            plan.installmentDetails?.firstInstallment?.amount
          )}`,
          deposit: `£${toFixedString(plan.installmentDetails?.deposit?.amount)}`,
          amountToBeFinanced: `${formatNegativePrice(
            Number(plan.installmentDetails?.financeAmount?.amount)
          )}`,
          intrestRate:
            plan.installmentDetails?.interestRate ||
            plan.installmentDetails?.interestRate === 0
              ? `${plan.installmentDetails?.interestRate}%`
              : '',
          representativeAPR:
            plan.installmentDetails?.aprFromProvider ||
            plan.installmentDetails?.aprFromProvider === 0
              ? `${toFixedString(plan.installmentDetails?.aprFromProvider)}%`
              : '',
          monthlyCost: `£${toFixedString(plan.installmentDetails?.monthlyCost?.amount)}`,
          numberOfInstallments:
            plan.installmentDetails?.numberOfInstallments ||
            plan.installmentDetails?.numberOfInstallments === 0
              ? plan.installmentDetails?.numberOfInstallments
              : 10,
          costForCredit: `£${plan.installmentDetails?.creditCharge?.toFixed(2)}`,
          total: `£${toFixedString(plan.installmentDetails?.total?.amount)}`,
        },
        isMta,
      };
    }
    return undefined;
  });

  return options.filter(isDefined);
};
