import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import defaultsDeep from 'lodash.defaultsdeep';
import { useContext } from 'react';
import { CreateMotorQuoteResponseDto } from '../../../api/v1';
import { QuoteJourneyStatusContext } from '../../../contexts';
import {
  hasEndorsement202Or208,
  hasEndorsement205,
  parseFormikStateToQuotePayload,
  parseQuoteResponseToFormikState,
} from '../../../helpers';
import { QuoteJourneyRoutes, quoteJourneyMap } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types';
import { useCreateQuoteMutation, useMarketingConsentCreate } from '../../mutations';
import { useQuoteFetchQuery } from '../../queries';
import { usePersonSubmit } from '../usePersonSubmit';

export const useCreateQuoteSubmit = () => {
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const { setIsError, setIsLoading, setCopy } = useContext(QuoteJourneyStatusContext);
  const navigate = useNavigateWithReferrer();
  const createGuestAccount = usePersonSubmit();
  const createQuoteMutation = useCreateQuoteMutation();
  const createMarketingConsent = useMarketingConsentCreate();
  const fetchQuote = useQuoteFetchQuery();

  const handleNavigateToStart = () => {
    navigate('../../quote');
  };

  const createQuoteDeclineHandler = ({
    resetForm,
    setSubmitting,
  }: FormikHelpers<QuoteJourneyFormData>) => {
    setIsError(true);
    resetForm();
    setSubmitting(false);
    setGlobalError(undefined, {
      title: 'Sorry, we can’t give you a quote',
      message: `We’ve looked at the details you’ve given us, and unfortunately, we can’t give you a quote at this time.`,
      cta: 'Go back to vehicle search',
      ctaId: 'quote-decline_cta',
      onClose: handleNavigateToStart,
    });
  };

  const createQuoteErrorHandler = (error: unknown) => {
    setIsError(true);
    setGlobalError(error, {
      ctaId: 'quote-error_cta',
    });
  };

  const parseQuoteToFormikState = async (
    quoteId: string,
    values: QuoteJourneyFormData,
    setValues: FormikHelpers<QuoteJourneyFormData>['setValues'],
    policyCorrelationId: string | null | undefined,
    quoteDateTimeUtc: string | null | undefined
  ) => {
    const quote = await fetchQuote(quoteId);
    const parsedQuote = parseQuoteResponseToFormikState(
      quote,
      policyCorrelationId,
      quoteDateTimeUtc
    );
    // overrides current state with parsed response, missing values are filled out from current state
    const mergedValuesWithQuote: QuoteJourneyFormData = defaultsDeep(parsedQuote, values);
    if (parsedQuote) {
      setValues(mergedValuesWithQuote);
    }
  };

  const createQuoteSuccessHandler = async (
    quoteData: CreateMotorQuoteResponseDto,
    values: QuoteJourneyFormData,
    setValues: FormikHelpers<QuoteJourneyFormData>['setValues']
  ) => {
    const pathAfterSubmission = quoteJourneyMap.getNext(QuoteJourneyRoutes.Cover);

    if (pathAfterSubmission && quoteData.id) {
      await parseQuoteToFormikState(
        quoteData.id,
        values,
        setValues,
        quoteData?.policyCorrelationId,
        quoteData.quoteDateTimeUtc
      );

      if (
        quoteData.endorsements &&
        hasEndorsement205(quoteData.endorsements) &&
        !hasEndorsement202Or208(quoteData.endorsements)
      ) {
        navigate(`${quoteData.id}/${QuoteJourneyRoutes.CarSecurityPolicyConditions}`);
      } else if (
        quoteData.endorsements &&
        hasEndorsement202Or208(quoteData.endorsements)
      ) {
        navigate(`${quoteData.id}/${QuoteJourneyRoutes.CarSecurity}`);
      } else {
        navigate(`${quoteData.id}/${QuoteJourneyRoutes.YourQuote}`);
      }
    }
  };

  const getCreateQuoteResponseHandler =
    (values: QuoteJourneyFormData, formikHelpers: FormikHelpers<QuoteJourneyFormData>) =>
    async (data: CreateMotorQuoteResponseDto) => {
      const isQuoteDeclined = data.declinatures && data.declinatures?.length > 0;
      if (isQuoteDeclined) {
        createQuoteDeclineHandler(formikHelpers);
      } else {
        await createQuoteSuccessHandler(data, values, formikHelpers.setValues);
      }
    };

  const getCreateQuoteSettledHandler =
    ({ setSubmitting, setTouched }: FormikHelpers<QuoteJourneyFormData>) =>
    () => {
      setSubmitting(false);
      setTouched({}, false);
      setIsLoading(false);
    };

  const createQuote = (
    values: QuoteJourneyFormData
  ): Promise<CreateMotorQuoteResponseDto> => {
    const quotePayload = parseFormikStateToQuotePayload(values);
    return createQuoteMutation.mutateAsync(quotePayload);
  };

  const handleCreateQuoteTimeout = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(null);
      }, 15000);
    });
  };

  const isDataCreateMotorQuoteResponse = (
    data: unknown
  ): data is CreateMotorQuoteResponseDto => !!data;

  const handleCreateQuoteRace =
    (values: QuoteJourneyFormData, formikHelpers: FormikHelpers<QuoteJourneyFormData>) =>
    (data: unknown) => {
      if (!isDataCreateMotorQuoteResponse(data)) {
        setGlobalError(null, {
          title: 'Something went wrong',
          message: 'Something went wrong. Please try again later on.',
          cta: 'Go back',
          ctaId: 'error-dialog_button',
          onClose: () => {
            setIsLoading(false);
            clearGlobalError();
          },
        });
      } else {
        getCreateQuoteResponseHandler(values, formikHelpers)(data);
      }
    };

  return async (
    values: QuoteJourneyFormData,
    formikHelpers: FormikHelpers<QuoteJourneyFormData>
  ) => {
    setCopy({
      title: 'Loading quote',
      subtitle: 'Fetching you our best price for your car insurance',
    });
    setIsLoading(true, 2500);
    await createGuestAccount(values, {
      onSuccess: () => {
        createMarketingConsent.mutateAsync(values.proposer.marketingPreferences);
        return Promise.race([createQuote(values), handleCreateQuoteTimeout()])
          .then(handleCreateQuoteRace(values, formikHelpers))
          .catch(createQuoteErrorHandler)
          .finally(getCreateQuoteSettledHandler(formikHelpers));
      },
    });
  };
};
