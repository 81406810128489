import * as Yup from 'yup';
import { addDays, isBefore, isAfter, isToday, isEqual } from 'date-fns';
import { combinedRegistrationRegex } from '@dayinsure/shared';
import { PolicyMtaPaths } from '../../routes';
import {
  getEndorsement208BaseOnEndorsement202,
  getIsSameRegPlate,
  isAnnualPaymentPlan,
  isTestContextExtended,
  isYes,
} from '../../helpers';

import { Driver } from '../../types/mtaJourneyForm';
import {
  additionalDriverDetailsValidationSchema as driverDetailsValidationSchema,
  vehicleUsageValidationSchema,
  yourLicenceAndHistoryValidationObject,
} from '../quoteJourney';
import { DriverFormData, YesNoAnswer } from '../../types';

const mtaStartDateValidation = Yup.date()
  .nullable()
  .required('Please check this')
  .test('startDate', 'Please check this', (value, ctx) => {
    const minStartDate = isTestContextExtended(ctx)
      ? ctx?.from.find(from => from.value.minStartDate)?.value?.minStartDate
      : undefined;
    const maxStartDate = isTestContextExtended(ctx)
      ? ctx?.from.find(from => from.value.maxStartDate)?.value?.maxStartDate
      : undefined;
    const chosenDate = new Date(value as Date);
    chosenDate.setHours(0, 0, 0, 0);
    const startDateLimit = new Date(maxStartDate as Date);
    if (minStartDate) {
      const startDate = new Date(minStartDate as Date);
      const endDate = addDays(new Date(startDate), 30);
      const dateIsBeforeStart = isBefore(chosenDate, startDate);
      const dateIsAfterEnd = isAfter(chosenDate, endDate);
      const dateIsAfterMaxEnd = isAfter(chosenDate, new Date(startDateLimit));
      return !dateIsAfterEnd && !dateIsBeforeStart && !dateIsAfterMaxEnd;
    }
    return false;
  });

const mtaExactStartHourValidation = Yup.object()
  .nullable()
  .required('Required')
  .test('today hours availability', 'Please check this', (value, ctx) => {
    const startDate = ctx.parent.startDateTimeUtc;

    if (!startDate || !value) {
      return true;
    }

    if (!isToday(startDate.parsedDate)) {
      return true;
    }

    const startTime = new Date(startDate.parsedDate);
    startTime.setHours(parseInt(value.id, 10));

    const now = new Date();
    now.setMinutes(0, 0, 0);

    return isBefore(now, startTime) || isEqual(now, startTime);
  });

const mtaExactStartMinuteValidation = Yup.object()
  .nullable()
  .required('Required')
  .test('today minutes availability', 'Please check this', (value, ctx) => {
    const startDate = ctx.parent.startDateTimeUtc;
    const startHour = ctx.parent.exactStartHour;

    if (!startDate || !startHour || !value) {
      return true;
    }

    if (!isToday(startDate.parsedDate)) {
      return true;
    }

    const startTime = new Date(startDate.parsedDate);
    startTime.setHours(parseInt(startHour.id, 10), parseInt(value.id, 10));

    return isBefore(Date.now(), startTime);
  });

export const carSecurityValidationSchema = Yup.object().shape({
  carSecurity: Yup.object().shape({
    isEndorsement202: Yup.bool(),
    isEndorsement208: Yup.bool(),
    approvedTracker: Yup.object()
      .nullable()
      .when(['isEndorsement202'], {
        is: (isEndorsement202: boolean) => isEndorsement202,
        then: Yup.object().nullable().required('Required'),
      }),
    antiCloneImmobiliser: Yup.object()
      .nullable()
      .when(['isEndorsement208', 'isEndorsement202', 'approvedTracker.id'], {
        is: (
          isEndorsement208: boolean,
          isEndorsement202: boolean,
          approvedTrackerId?: YesNoAnswer
        ) =>
          isEndorsement208 &&
          getEndorsement208BaseOnEndorsement202(isEndorsement202, approvedTrackerId),
        then: Yup.object().nullable().required('Required'),
      }),
    immobiliser: Yup.object()
      .nullable()
      .when(['antiCloneImmobiliser.id'], {
        is: isYes,
        then: Yup.object().nullable().required('Required'),
      }),
    installationProof: Yup.object()
      .nullable()
      .when(['immobiliser.id'], {
        is: (immobiliserId?: string) => immobiliserId && immobiliserId !== '5',
        then: Yup.object().nullable().required('Required'),
      }),
  }),
});

export const policyChangedValidationSchema = Yup.object().shape({
  policyChange: Yup.object()
    .shape({
      changeCar: Yup.boolean(),
      changeReg: Yup.boolean(),
      changeDrivers: Yup.boolean(),
    })
    .test('One of is true', 'Please check this', values => {
      return Object.values(values).some(val => val);
    }),
});

export const registrationPlateValidationSchema = Yup.string()
  .matches(combinedRegistrationRegex, {
    message: 'Please enter a valid registration number',
    excludeEmptyString: true,
  })
  .required('Required');

const carPartValidationSchema = Yup.object()
  .nullable()
  .when(['registrationNumber'], {
    is: (regNum: string) => regNum,
    then: Yup.object().required('Required'),
  });

const newRegValidationSchema = Yup.object().shape({
  cover: Yup.object().shape({
    startDateTimeUtc: Yup.object()
      .nullable()
      .shape({
        parsedDate: mtaStartDateValidation,
        day: Yup.string().required('Required'),
        month: Yup.string().required('Required'),
        year: Yup.string().required('Required'),
      })
      .required('Required'),
    exactStartHour: mtaExactStartHourValidation,
    exactStartMinute: mtaExactStartMinuteValidation,
  }),
  newReg: Yup.object()
    .nullable()
    .shape({
      notChanging: Yup.bool().oneOf([true], 'Required'),
      regPlate: registrationPlateValidationSchema.test(
        'is the same registration plate as previous car',
        'Please check this',
        (_value, ctx) => !getIsSameRegPlate(ctx)
      ),
    }),
});

const newCarValidationSchema = Yup.object().shape({
  cover: Yup.object().shape({
    startDateTimeUtc: Yup.object()
      .nullable()
      .shape({
        parsedDate: mtaStartDateValidation,
        day: Yup.string().required('Required'),
        month: Yup.string().required('Required'),
        year: Yup.string().required('Required'),
      })
      .required('Required'),
    exactStartHour: mtaExactStartHourValidation,
    exactStartMinute: mtaExactStartMinuteValidation,
  }),
  vehicle: Yup.object()
    .nullable()
    .shape({
      regPlate: registrationPlateValidationSchema.test(
        'is the same registration plate as previous car',
        'Please check this',
        (_value, ctx) => !getIsSameRegPlate(ctx)
      ),
      registrationNumber: Yup.string(),
      steeringWheelSide: carPartValidationSchema,
      numberOfSeats: carPartValidationSchema,
      isImported: carPartValidationSchema,
      trackingDevice: Yup.object()
        .nullable()
        .shape({
          hasTrackingDevice: carPartValidationSchema,
          code: Yup.object()
            .nullable()
            .when(['hasTrackingDevice.id'], {
              is: isYes,
              then: Yup.object().nullable().required('Required'),
            }),
        }),
      alarmImmobiliser: carPartValidationSchema,
      areDetailsCorrect: Yup.bool().when(['registrationNumber'], {
        is: (regNum: string) => regNum,
        then: Yup.bool().oneOf([true], 'Required'),
      }),
      selfDeclaredVehicleValue: Yup.number()
        .transform(value => (Number.isNaN(value) ? null : value))
        .nullable()
        .required('Required')
        .positive('Please check this')
        .integer('Please check this')
        .min(1, 'Please check this')
        .max(1000000, 'Please check this'),
      isCarModified: Yup.object().nullable().required('Required'),
      isAddingModification: Yup.bool().oneOf([false], 'Required'),
      modifications: Yup.array().when('isCarModified.id', {
        is: isYes,
        then: Yup.array()
          .of(Yup.object().nullable().shape({ id: Yup.string(), name: Yup.string() }))
          .min(1, 'Required'),
      }),
      ownership: Yup.object().shape({
        isPurchased: Yup.object().nullable().required('Required'),
        selfDeclaredDateOfPurchase: Yup.object()
          .nullable()
          .required('Required')
          .when(['isPurchased.id'], {
            is: isYes,
            then: Yup.object()
              .nullable()
              .shape({
                parsedDate: Yup.date()
                  .nullable()
                  .required('Please check this')
                  .min(new Date(1935, 0), 'Please check this')
                  .max(new Date(), 'Please check this'),
                month: Yup.string().required('Required'),
                year: Yup.string().required('Required'),
              }),
          }),
        registerdKeeper: Yup.object().nullable().required('Required'),
        legalOwner: Yup.object().nullable().required('Required'),
      }),
    }),
});

const cardFieldValidation = Yup.string()
  .nullable()
  .when(['usualPaymentFrequency.code', 'isDirectDebitConfirmed', 'isRefund'], {
    is: (code: { id?: string }, isDirectDebitConfirmed: boolean, isRefund: boolean) => {
      return !isRefund && (isAnnualPaymentPlan(code?.id) || isDirectDebitConfirmed);
    },
    then: Yup.string().nullable().required('Required'),
  });

const paymentValidationSchema = Yup.object()
  .nullable()
  .shape({
    encryptedCardNumber: cardFieldValidation,
    cardholderName: cardFieldValidation,
    encryptedExpiryMonth: cardFieldValidation,
    encryptedExpiryYear: cardFieldValidation,
    encryptedSecurityCode: cardFieldValidation,
    isConfirmed: Yup.boolean(),
    isAnnualImportantInformationConfirmed: Yup.boolean().when(
      ['usualPaymentFrequency.code', 'isRefund'],
      {
        is: (code: { id?: string }, isRefund: boolean) => {
          return !isRefund && isAnnualPaymentPlan(code?.id);
        },
        then: Yup.boolean().oneOf([true], 'Required'),
      }
    ),
    isRefund: Yup.boolean(),
  });

const driversValidationSchema = Yup.object().shape({
  cover: Yup.object().shape({
    startDateTimeUtc: Yup.object()
      .nullable()
      .shape({
        parsedDate: mtaStartDateValidation,
        day: Yup.string().required('Required'),
        month: Yup.string().required('Required'),
        year: Yup.string().required('Required'),
      })
      .required('Required'),
    exactStartHour: mtaExactStartHourValidation,
    exactStartMinute: mtaExactStartMinuteValidation,
  }),
  drivers: Yup.object().shape({
    drivers: Yup.array().of(Yup.object({}).nullable()),
    driversToAdd: Yup.array().of(Yup.object({}).nullable()),
    mainDriver: Yup.object()
      .nullable()
      .when(['drivers', 'driversToAdd'], {
        is: (drivers: Driver[], driversToAdd: DriverFormData[]) =>
          driversToAdd.length + drivers.length > 1,
        then: Yup.object().nullable().required('Required'),
      }),
    hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: Yup.object()
      .nullable()
      .when(['driversToAdd'], {
        is: (driversToAdd: DriverFormData[]) => driversToAdd.length > 0,
        then: Yup.object().nullable().required('Required'),
      }),
  }),
});

const additionalDriverDetailsValidationSchema = Yup.object().shape({
  drivers: Yup.object().shape({
    driversToAdd: Yup.array().of(driverDetailsValidationSchema),
  }),
});

const additionalDriverLicenceAndHistoryValidationSchema = Yup.object().shape({
  drivers: Yup.object().shape({
    driversToAdd: Yup.array().of(yourLicenceAndHistoryValidationObject),
  }),
});

export const getValidationMtaSchemaFromRoute = (route: string) => {
  if (route.includes(PolicyMtaPaths.NewCarUsage)) {
    return vehicleUsageValidationSchema;
  }
  if (route.includes(PolicyMtaPaths.NewCar)) {
    return newCarValidationSchema;
  }
  if (route.includes(PolicyMtaPaths.NewReg)) {
    return newRegValidationSchema;
  }
  if (
    route.includes(PolicyMtaPaths.Drivers) &&
    !(
      route.includes(PolicyMtaPaths.DriverDetails) ||
      route.includes(PolicyMtaPaths.DriverLicenceAndHistory)
    )
  ) {
    return driversValidationSchema;
  }
  if (
    route.includes(PolicyMtaPaths.Drivers) &&
    route.includes(PolicyMtaPaths.DriverDetails)
  ) {
    return additionalDriverDetailsValidationSchema;
  }
  if (
    route.includes(PolicyMtaPaths.Drivers) &&
    route.includes(PolicyMtaPaths.DriverLicenceAndHistory)
  ) {
    return additionalDriverLicenceAndHistoryValidationSchema;
  }
  if (route.includes(PolicyMtaPaths.PolicyChange)) {
    return policyChangedValidationSchema;
  }
  if (route.includes(PolicyMtaPaths.Payment)) {
    return paymentValidationSchema;
  }
  if (route.includes(PolicyMtaPaths.CarSecurity)) {
    return carSecurityValidationSchema;
  }
  return undefined;
};
