import { useParams } from 'react-router-dom';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { useContext } from 'react';
import { usePostMtaQuoteMutation } from '../../mutations/usePostMtaQuoteMutation';
import { MtaQuoteDto } from '../../../api/v1';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { hasEndorsement202Or208, hasEndorsement205 } from '../../../helpers';
import { PolicyMtaPaths } from '../../../routes';
import { QuoteJourneyStatusContext } from '../../../contexts';

export const useCreateMtaQuoteSubmit = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const { setIsError } = useContext(QuoteJourneyStatusContext);
  const { id } = useParams<{ id: string }>();
  const postMtaQuoteMutation = usePostMtaQuoteMutation(id);
  const navigate = useNavigateWithReferrer();

  const onSuccess = (data: MtaQuoteDto, carWasChanged?: boolean) => {
    const { quoteId, endorsements } = data;
    if (
      endorsements &&
      carWasChanged &&
      hasEndorsement205(endorsements) &&
      !hasEndorsement202Or208(endorsements)
    ) {
      navigate(`${quoteId}/${PolicyMtaPaths.CarSecurityPolicyConditions}`);
    } else if (endorsements && carWasChanged && hasEndorsement202Or208(endorsements)) {
      navigate(`${quoteId}/${PolicyMtaPaths.CarSecurity}`);
    } else {
      navigate(`${quoteId}/${PolicyMtaPaths.NewCost}`);
    }
  };

  const createQuoteDeclineHandler = ({
    resetForm,
    setSubmitting,
  }: FormikHelpers<MtaJourneyFormData>) => {
    setIsError(true);
    resetForm();
    setSubmitting(false);
    setGlobalError(undefined, {
      title: 'Please note',
      message: `We’ve looked at the details you’ve given us, and unfortunately, we can’t give you a quote at this time.`,
      cta: 'Go back to policy info',
      ctaId: 'quote-decline_cta',
      onClose: () => navigate('../'),
    });
  };

  const getCreateQuoteSettledHandler =
    ({ setSubmitting, setTouched }: FormikHelpers<MtaJourneyFormData>) =>
    () => {
      setSubmitting(false);
      setTouched({}, false);
    };
  return (
    values: MtaJourneyFormData,
    formikHelpers: FormikHelpers<MtaJourneyFormData>
  ) => {
    postMtaQuoteMutation.mutate(values, {
      onSuccess: (data: MtaQuoteDto) => onSuccess(data, !!values?.vehicle?.regPlate),
      onError: () => createQuoteDeclineHandler(formikHelpers),
      onSettled: getCreateQuoteSettledHandler(formikHelpers),
    });
  };
};
