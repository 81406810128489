import type { OrderDto } from '../models/OrderDto';
import type { PlaceOrderRequestDto } from '../models/PlaceOrderRequestDto';
import type { PlaceOrderResponseDto } from '../models/PlaceOrderResponseDto';
import type { ResumeOrderRequestDto } from '../models/ResumeOrderRequestDto';
import type { ResumeOrderResponseDto } from '../models/ResumeOrderResponseDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class OrderService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  postOrder(
    requestBody?: PlaceOrderRequestDto
  ): CancelablePromise<PlaceOrderResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order',
      body: requestBody,
    });
  }

  postOrderResume(
    id: string,
    requestBody?: ResumeOrderRequestDto
  ): CancelablePromise<ResumeOrderResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order/{id}/resume',
      path: {
        id,
      },
      body: requestBody,
    });
  }

  getOrder(id: string): CancelablePromise<OrderDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/order/{id}',
      path: {
        id,
      },
    });
  }
}
