import type { OpenAPIConfig } from '../core/OpenAPI';

import { MarketingConsentService } from './services/MarketingConsentService';
import { MotorPolicyService } from './services/MotorPolicyService';
import { MotorQuoteService } from './services/MotorQuoteService';
import { OrderService } from './services/OrderService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { PcwDeeplinkService } from './services/PcwDeeplinkService';
import { PersonService } from './services/PersonService';
import { VehicleService } from './services/VehicleService';
import { BaseAPI } from '../core/BaseAPI';

export class Dayinsure extends BaseAPI {
  readonly marketingConsent: MarketingConsentService;
  readonly motorPolicy: MotorPolicyService;
  readonly motorQuote: MotorQuoteService;
  readonly order: OrderService;
  readonly paymentMethod: PaymentMethodService;
  readonly pcwDeeplink: PcwDeeplinkService;
  readonly person: PersonService;
  readonly vehicle: VehicleService;

  constructor(config?: Partial<OpenAPIConfig>) {
    super(config);

    this.marketingConsent = new MarketingConsentService(this.request);
    this.motorPolicy = new MotorPolicyService(this.request);
    this.motorQuote = new MotorQuoteService(this.request);
    this.order = new OrderService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.pcwDeeplink = new PcwDeeplinkService(this.request);
    this.person = new PersonService(this.request);
    this.vehicle = new VehicleService(this.request);
  }
}
