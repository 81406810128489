import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import {
  ApiError,
  CreateMtaQuoteDto,
  Dayinsure as DayinsureAPIv1,
  DriverDto,
  MtaQuoteDto,
  OpenAPI,
} from '../../../api/v1';
import { QuoteJourneyStatusContext } from '../../../contexts';
import {
  driverStateParser,
  entriesToReferenceCodes,
  isYes,
  parsedDateToISO,
  parseNumericEntry,
  toReferenceCode,
} from '../../../helpers';
import { query, usePolicyQuery } from '../../queries';
import { Driver, MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { BaseDataEntry, DateEntry, DriverFormData } from '../../../types';
import { setMainDriver } from '../../../pages/Policies/PoliciesMta/Drivers/Drivers.utils';

const createDriversToUpdateArr = (values: MtaJourneyFormData) => {
  const driversToUpdateArr = values.drivers?.drivers?.reduce(
    (res: Driver[], driver: Driver) => {
      const isMainDriver = driver.id === values.drivers.mainDriver?.id;
      const updatedDriver = { ...driver, isMainDriver };
      delete updatedDriver.isPolicyholder;
      if (isMainDriver && !driver.isMainDriver) {
        return [...res, updatedDriver];
      }
      if (!isMainDriver && driver.isMainDriver) {
        return [...res, updatedDriver];
      }
      return res;
    },
    []
  );
  return driversToUpdateArr.length > 0 ? driversToUpdateArr : null;
};

const parseEffectiveDateUtc = (
  startDate: DateEntry | null,
  startHour: BaseDataEntry | null,
  startMinute: BaseDataEntry | null
) => {
  if (!startDate || !startHour || !startMinute) {
    return undefined;
  }
  const startTime = new Date(startDate.parsedDate);
  startTime.setHours(parseInt(startHour.id, 10), parseInt(startMinute.id, 10));

  return parsedDateToISO(startTime.toISOString());
};

const getQuoteMtaPayload = (
  values: MtaJourneyFormData,
  policyholderPostcode?: string | null
) => {
  let payload: CreateMtaQuoteDto = {
    hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: isYes(
      values.drivers.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled?.id
    ),
    effectiveDateUtc: parseEffectiveDateUtc(
      values.cover.startDateTimeUtc,
      values.cover.exactStartHour,
      values.cover.exactStartMinute
    ),
    vehicleSwap: undefined,
    vehicleUpdate: undefined,
    driversToRemove: null,
    driversToAdd: null,
    driversToUpdate: null,
    referrer: 'Everyday',
    salesChannel: 'Direct',
    salesPlatform: 'Web',
  };

  if (values.policyChange.changeCar) {
    payload = {
      ...payload,
      vehicleSwap: {
        type: { code: values.vehicle.type?.id },
        make: values.vehicle.make?.name,
        model: values.vehicle.model?.name,
        abiCode: values.vehicle.abiCode?.name,
        isImported: isYes(values.vehicle.isImported?.id),
        modifications: entriesToReferenceCodes(values?.vehicle?.modifications),
        numberOfSeats: parseNumericEntry(values.vehicle?.numberOfSeats || null),
        ...(values.vehicle.numberOfDoors && {
          numberOfDoors: parseNumericEntry(values.vehicle.numberOfDoors || null),
        }),
        weightInKg: parseNumericEntry(values.vehicle.weightInKg || null),
        manufactureYear: parseNumericEntry(values.vehicle.manufactureYear || null),
        manufactureStartYear: parseNumericEntry(
          values.vehicle.manufactureStartYear || null
        ),
        manufactureEndYear: parseNumericEntry(values.vehicle.manufactureEndYear || null),
        selfDeclaredVehicleValue: {
          amount: values.vehicle.selfDeclaredVehicleValue ?? undefined,
          currency: 'GBP',
        },
        usage: {
          type: toReferenceCode(values.vehicle.usage.type),
          annualMileage: values.vehicle.usage.annualMileage || undefined,
          businessMileage: values.vehicle.usage.businessMileage || undefined,
        },
        ownership: {
          isPurchased: isYes(values.vehicle?.ownership?.isPurchased?.id),
          registeredKeeper: {
            code: values.vehicle.ownership?.registerdKeeper?.id,
          },
          legalOwner: {
            code: values.vehicle.ownership?.legalOwner?.id,
          },
          ...(values.vehicle.ownership?.selfDeclaredDateOfPurchase?.parsedDate && {
            selfDeclaredDateOfPurchase:
              values.vehicle.ownership?.selfDeclaredDateOfPurchase?.parsedDate,
          }),
        },
        location: {
          daytimeLocation: toReferenceCode(values.vehicle.location.daytimeLocation),
          overnightLocation: toReferenceCode(values.vehicle.location.overnightLocation),
          overnightLocationPostcode:
            values.vehicle.location.overnightLocationPostcode || policyholderPostcode,
        },
        bodyType: values.vehicle.bodyType?.id,
        vin: values.vehicle.vin?.id,
        registrationNumber: values.vehicle.registrationNumber,
        ...(values.vehicle.steeringWheelSide?.id && {
          steeringWheelSide: { code: values.vehicle.steeringWheelSide.id },
        }),
        ...(values.vehicle.transmission?.id && {
          transmission: { code: values.vehicle.transmission.id },
        }),
        ...(values.vehicle.primaryFuelType?.id && {
          primaryFuelType: { code: values.vehicle.primaryFuelType.id },
        }),
        ...(values.vehicle.cubicCapicityInLitres?.id && {
          cubicCapicityInLitres: parseNumericEntry(values.vehicle.cubicCapicityInLitres),
        }),
        ...(values.vehicle.trackingDevice?.code && {
          trackingDevice: {
            code: values.vehicle.trackingDevice.code.id,
            description: values.vehicle.trackingDevice.code.name,
          },
        }),
        ...(values.vehicle.alarmImmobiliser?.id && {
          alarmImmobiliser: {
            type: {
              code: values.vehicle.alarmImmobiliser.id,
            },
            model: null,
            installationProofAvailable: true,
          },
        }),
      },
    };
  }

  if (values.policyChange.changeReg) {
    payload = {
      ...payload,
      vehicleUpdate: {
        id: values.newReg.vehicleId,
        registrationNumber: values.newReg.regPlate,
      },
    };
  }

  if (values.policyChange.changeDrivers) {
    payload = {
      ...payload,
      driversToRemove:
        values.drivers.driversToRemove.length > 0 ? values.drivers.driversToRemove : null,
      driversToAdd:
        values.drivers.driversToAdd.length > 0
          ? values.drivers.driversToAdd
              .map(setMainDriver(values.drivers.mainDriver?.id))
              .map((driver: DriverFormData) => driverStateParser(driver, undefined))
              .map((driver: DriverDto) => {
                const newDriver = driver;
                delete newDriver.isProposer;
                return newDriver;
              })
          : null,
      driversToUpdate: createDriversToUpdateArr(values),
    };
  }

  return payload;
};
export const usePostMtaQuoteMutation = (policyId: string | undefined) => {
  const { id } = useParams<{ id: string }>();
  const { data: policy } = usePolicyQuery(id);
  let timerRef: ReturnType<typeof setTimeout>;
  const { setGlobalError } = useContext(GlobalErrorContext);
  const queryClient = useQueryClient();
  const { setIsLoading, setCopy } = useContext(QuoteJourneyStatusContext);
  const postQuote = (values: MtaJourneyFormData) => {
    if (!policyId) {
      throw new Error('No quote ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    const payload = getQuoteMtaPayload(
      values,
      policy?.policyholder?.address?.formattedAddress?.postCode
    );
    setCopy({
      title: 'Loading quote',
      subtitle: 'Fetching you our best price for your car insurance',
    });
    setIsLoading(true);
    return api.motorPolicy.postMotorPolicyMtaQuote(policyId, payload);
  };

  return useMutation(postQuote, {
    onMutate: () => {
      timerRef = setTimeout(async () => {
        await queryClient?.cancelMutations();
      }, 15000);
    },
    onSuccess: (data: MtaQuoteDto) => {
      clearTimeout(timerRef);
      queryClient.setQueryData<MtaQuoteDto>(query.mtaQuote(data.quoteId || ''), () => {
        // TODO there is no declinatures in model, will be added, after it will be added remove ignore comment
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const formIsDeclined = !!(data?.declinatures && data?.declinatures?.length > 0);
        if (formIsDeclined) {
          setIsLoading(false);
          throw new ApiError(
            {
              statusText: 'decline',
              status: 404,
              body: {
                error: 'form is declined',
              },
              url: '',
              ok: true,
            },
            'form is declined'
          );
        }
        setIsLoading(false);
        return data;
      });
    },
    onError: (err: ApiError) => {
      clearTimeout(timerRef);
      setIsLoading(false);
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
        return;
      }

      if (err?.status !== 404) {
        setGlobalError(err);
      }
      if (!err?.status) {
        setGlobalError(err, {
          title: 'Something went wrong',
          message: 'Something went wrong. Please try again later on.',
        });
        throw new ApiError(
          {
            statusText: 'canceled',
            status: 422,
            body: {
              error: 'Try again',
            },
            url: '',
            ok: true,
          },
          'Request has been canceled please try again'
        );
      }
    },
  });
};
