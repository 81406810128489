import { Button, Card, Dialog } from '@dayinsure/components';
import { Row } from './Row';
import {
  fixNegativePriceDisplay,
  findAdministrationFee,
  isMonthlyPaymentPlan,
  isRefund,
  isSamePrice,
} from '../../../../helpers';
import {
  AddOnModelDto,
  FeeDto,
  MotorPolicyAddOnModelDto,
  MotorPolicyTransactionDto,
} from '../../../../api/v1';

const getContentBaseOnTransaction = (transaction: MotorPolicyTransactionDto) => {
  const isMonthly = isMonthlyPaymentPlan(transaction.paymentPlan?.type?.code);
  const refund = isRefund(transaction.paymentPlan);
  const samePrice = isSamePrice(transaction.paymentPlan);
  const administrationFee = findAdministrationFee(transaction.price?.fees);
  const installmentDetails = transaction.paymentPlan?.installmentDetails;
  const amendmentFee = transaction.price?.fees?.find(
    (fee: FeeDto) => fee.type === 'MTAFee'
  );
  const protectedNCBAddon = transaction?.price?.addOns?.find(
    (addOn: AddOnModelDto) => addOn.type?.code === 'PROTECTED_NCB'
  );

  switch (transaction.type?.toLowerCase()) {
    case 'NewBusiness'.toLowerCase(): {
      const base = [
        isMonthly && (
          <Row
            label="Initial payment"
            value={`£${transaction.paymentPlan?.installmentDetails?.deposit?.amount?.toFixed(
              2
            )}`}
          />
        ),
        <Row
          key="Insurance premium"
          label="Insurance premium"
          value={`£${transaction.price?.netPremium?.amount?.toFixed(2)}`}
        />,
        transaction.price?.addOns?.map(addOn => (
          <Row
            key={addOn.type?.code}
            label={addOn.type?.description || ''}
            value={`£${(addOn.price?.total?.amount || 0).toFixed(2)}`}
          />
        )),
        administrationFee?.total?.amount && (
          <Row
            key="Set-up fee (non-refundable)"
            label="Set-up fee (non-refundable)"
            value={`£${administrationFee.total.amount.toFixed(2)}`}
          />
        ),
        <Row
          key="Insurance premium tax"
          label="Insurance premium tax"
          value={`£${transaction.price?.insurancePremiumTax?.amount?.toFixed(2)}`}
        />,
      ];

      if (isMonthly) {
        base.push(
          <Row
            key="Amount to be financed"
            label="Amount to be financed"
            value={`£${installmentDetails?.financeAmount?.amount?.toFixed(2)}`}
          />,
          <Row
            key="Interest rate"
            label="Interest rate"
            value={`${installmentDetails?.interestRate}%`}
          />,
          <Row
            key="Representative APR"
            label="Representative APR"
            value={`${installmentDetails?.aprFromProvider?.toFixed(2)}%`}
          />,
          <Row
            key={`${installmentDetails?.numberOfInstallments} monthly payments`}
            label={`${installmentDetails?.numberOfInstallments} monthly payments`}
            value={`£${installmentDetails?.monthlyCost?.amount?.toFixed(2)}`}
          />
        );
      }

      base.push(
        <Row
          key={isMonthly ? 'Total amount payable' : 'Total paid'}
          label={isMonthly ? 'Total amount payable' : 'Total paid'}
          value={`£${transaction?.paymentPlan?.installmentDetails?.total?.amount?.toFixed(
            2
          )}`}
        />
      );

      return base;
    }

    case 'MidTermAdjustment'.toLowerCase(): {
      if (samePrice) {
        return [
          <Row
            key="Amendment fee"
            label="Amendment fee"
            value={fixNegativePriceDisplay(amendmentFee?.total?.amount)}
          />,
          <Row
            key="Policy cost difference"
            label="Policy cost difference"
            value={fixNegativePriceDisplay(transaction.price?.netPremium?.amount)}
          />,
          <Row
            key="Total cost for changes"
            label="Total cost for changes"
            value={fixNegativePriceDisplay(transaction.price?.netPremium?.amount)}
          />,
        ];
      }

      if (isMonthly) {
        return [
          <Row
            key="Amendment fee"
            label="Amendment fee"
            value={fixNegativePriceDisplay(amendmentFee?.total?.amount)}
          />,
          <Row
            key="Insurance premium"
            label="Insurance premium"
            value={fixNegativePriceDisplay(transaction.price?.netPremium?.amount)}
          />,
          <Row
            key="Insurance premium tax"
            label="Insurance premium tax"
            value={fixNegativePriceDisplay(
              transaction.price?.insurancePremiumTax?.amount
            )}
          />,
          protectedNCBAddon && (
            <Row
              key="Protected NCB cost difference"
              label="Protected NCB cost difference"
              value={fixNegativePriceDisplay(protectedNCBAddon?.price?.total?.amount)}
            />
          ),
          <Row
            key="Amount to be financed"
            label="Amount to be financed"
            value={fixNegativePriceDisplay(installmentDetails?.financeAmount?.amount)}
          />,
          <Row
            key="Cost for credit"
            label="Cost for credit"
            value={fixNegativePriceDisplay(installmentDetails?.costOfCredit?.amount)}
          />,
          <Row
            key={refund ? 'Total refund' : 'Total amount payable'}
            label={refund ? 'Total refund' : 'Total amount payable'}
            value={fixNegativePriceDisplay(installmentDetails?.total?.amount)}
          />,
        ];
      }

      return [
        <Row
          key="Amendment fee"
          label="Amendment fee"
          value={fixNegativePriceDisplay(amendmentFee?.total?.amount)}
        />,
        <Row
          key="Insurance premium tax"
          label="Insurance premium tax"
          value={fixNegativePriceDisplay(transaction.price?.insurancePremiumTax?.amount)}
        />,
        <Row
          key="Policy cost difference"
          label="Policy cost difference"
          value={fixNegativePriceDisplay(transaction.price?.netPremium?.amount)}
        />,
        protectedNCBAddon && (
          <Row
            key="Protected NCB cost difference"
            label="Protected NCB cost difference"
            value={fixNegativePriceDisplay(protectedNCBAddon?.price?.total?.amount)}
          />
        ),
        <Row
          key={refund ? 'Total refund' : 'Total cost for changes'}
          label={refund ? 'Total refund' : 'Total cost for changes'}
          value={fixNegativePriceDisplay(transaction?.price?.total?.amount)}
        />,
      ];
    }

    case 'Cancellation'.toLowerCase(): {
      const displayAddOnsInfo = (transaction?.price?.addOns?.length || 0) > 0;
      const addOnsPremium = transaction.price?.addOns?.reduce(
        (prev: number, next: MotorPolicyAddOnModelDto) => {
          if (typeof next?.price?.netPremium?.amount === 'number') {
            return prev + next.price.netPremium.amount;
          }
          return prev;
        },
        0
      );
      const addOnsPremiumTax = transaction.price?.addOns?.reduce(
        (prev: number, next: MotorPolicyAddOnModelDto) => {
          if (typeof next?.price?.insurancePremiumTax?.amount === 'number') {
            return prev + next.price.insurancePremiumTax.amount;
          }
          return prev;
        },
        0
      );
      const cancellationFee = transaction.price?.fees?.find(
        (fee: FeeDto) => fee.type === 'CancellationFee'
      );

      return [
        <Row
          key="Insurance premium"
          label="Insurance premium"
          value={fixNegativePriceDisplay(transaction.price?.netPremium?.amount)}
        />,
        <Row
          key="Insurance premium tax"
          label="Insurance premium tax"
          value={fixNegativePriceDisplay(transaction.price?.insurancePremiumTax?.amount)}
        />,
        displayAddOnsInfo && (
          <Row
            key="Add-on Insurance Premium"
            label="Add-on Insurance Premium"
            value={fixNegativePriceDisplay(addOnsPremium)}
          />
        ),
        displayAddOnsInfo && (
          <Row
            key="Add-on Insurance premium tax"
            label="Add-on Insurance premium tax"
            value={fixNegativePriceDisplay(addOnsPremiumTax)}
          />
        ),
        <Row
          key="Cancellation fee"
          label="Cancellation fee"
          value={fixNegativePriceDisplay(cancellationFee?.total?.amount)}
        />,
        <Row
          key="Total cost for cancellation"
          label="Total cost for cancellation"
          value={fixNegativePriceDisplay(transaction.price?.total?.amount)}
        />,
      ];
    }
    case 'Reversal'.toLowerCase():
      return '';
    default:
      return '';
  }
};

type PaymentDetailModalProps = {
  isOpen: boolean;
  testId: string;
  transaction: MotorPolicyTransactionDto;
  onClose: () => void;
};

export const PaymentDetailModal = ({
  isOpen,
  testId,
  transaction,
  onClose,
}: PaymentDetailModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title="Payment details">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <div className="flex flex-col w-full">
          {getContentBaseOnTransaction(transaction)}
        </div>
      </Card>

      <Button
        id={`${testId}_payment-detail_close-button`}
        testId={`${testId}_payment-detail_close-button`}
        fullWidth
        onClick={onClose}
        text="Close"
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};
