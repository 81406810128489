import type { AddressDto } from '../models/AddressDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class AddressService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getApiV3Address(
    postcode: string,
    houseNumber?: string
  ): CancelablePromise<AddressDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/address/{postcode}',
      path: {
        postcode,
      },
      query: {
        houseNumber,
      },
    });
  }

  getApiV3AddressDetail(postkey: string): CancelablePromise<AddressDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/address/{postkey}/detail',
      path: {
        postkey,
      },
    });
  }
}
