import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { ApiError, Dayinsure as DayInsureAPI, OpenAPI } from '../../../api/v1';
import { MarketingPreferences } from '../../../types';
import { toMarketingPreferencesDto } from '../../../helpers';
import { query } from '../../queries';

export const useMarketingConsentCreate = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const queryClient = useQueryClient();
  const createMarketingConsent = (preferences: MarketingPreferences) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.marketingConsent.postMarketingConsentCreate(
      toMarketingPreferencesDto(preferences)
    );
  };

  return useMutation(createMarketingConsent, {
    mutationKey: 'create-marketing-consent',
    retry: count => count < 3,
    retryDelay: 500,
    onSuccess: data => {
      queryClient.setQueryData(query.marketingConsent, () => data);
    },
    onError: (error: ApiError) => {
      if (error.status !== 401) {
        setGlobalError(error, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
      }
    },
  });
};
