import { Button, ContentContainer, PageContainer } from '@dayinsure/components';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useEffect, useState } from 'react';
import { useMotorPoliciesSummaryQuery, usePolicyQuery } from '../../../hooks';
import { PolicyInfoCar } from '../PolicyInfo/PolicyInfoCar';
import { PolicyInfoCover } from '../PolicyInfo/PolicyInfoCover';
import { PolicyInfoDrivers } from '../PolicyInfo/PolicyInfoDrivers';
import { PolicyInfoCovers } from '../PolicyInfo/PolicyInfoCovers';
import { PolicyInfoPayments } from '../PolicyInfo/PolicyInfoPayments';
import { ConditionsCard } from '../../../components/Quote';
import { PolicyDocuments } from '../../../components/PolicyDocuments';
import { FeesAndCharges } from '../../Quote/Review/FeesAndCharges';
import { ExcessesCard, Loader } from '../../../components';
import { NewMonthlyPayments } from '../../Quote';
import { PaymentTypesEnum } from '../../../constants/payments';
import { PaymentCard } from '../../../components/PaymentCard';
import { transformPlanForFormikValue } from '../../../helpers';
import { CancelChangesDialog } from './CancelChangesDialog';

const testId = 'upcoming-policy-changes';
export const UpcomingPolicyChanges = () => {
  const [params] = useSearchParams();
  const policyVersion = params.get('policyVersion');
  const [version, setVersion] = useState<number | undefined>(
    Number(policyVersion) || undefined
  );
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigateWithReferrer();
  const { data: policy, refetch, isRefetching } = usePolicyQuery(id, version);
  const { data: motorQuote } = useMotorPoliciesSummaryQuery();
  const policyDetails = motorQuote?.find(mq => mq.id === id);
  const usualPaymentFrequency = transformPlanForFormikValue(
    policy?.snapshotTransaction?.paymentPlan
  );
  const isPlanAnnual = usualPaymentFrequency?.code?.id === PaymentTypesEnum.ANNUAL;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const policyHolderDriver = policy?.drivers?.find(driver => driver.isPolicyholder);
  const handleBackToPolicy = () => {
    navigate(`/policies/${id}`);
  };

  const handleCancelChanges = () => setIsDialogOpen(true);

  const handleCloseDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    if (!version && policy?.pendingChanges?.policyVersion) {
      setVersion(policy.pendingChanges.policyVersion);
    }
  }, [policy?.pendingChanges?.policyVersion, version]);

  if (!policy || !policyDetails) {
    return <Loader />;
  }

  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Upcoming policy changes
      </h1>
      <ContentContainer>
        {id && (
          <>
            {policy?.vehicle?.registrationNumber && (
              <PolicyInfoCar testId={testId} vehicle={policy.vehicle} noMoreButton />
            )}
            {policy?.pendingChanges?.effectiveDateTimeUtc && policyDetails?.endDate && (
              <PolicyInfoCover
                testId={testId}
                startDate={policy.pendingChanges.effectiveDateTimeUtc}
                endDate={policyDetails?.cancelledDate || policyDetails.endDate}
                customStartTitle="Changes start"
              />
            )}
            {policy?.drivers?.length && policy?.policyholder && (
              <PolicyInfoDrivers
                testId={testId}
                drivers={policy.drivers}
                policyHolder={policy.policyholder}
                noMoreButton
              />
            )}
            {policy?.cover && (
              <PolicyInfoCovers
                testId={testId}
                cover={policy.cover}
                addons={policy.addOns}
                noClaimsHistory={policyHolderDriver?.noClaimsHistory}
                isAutoRenewalRequested={policy.isAutoRenewalRequested}
                refetch={refetch}
                isRefetching={isRefetching}
                upcomingChanges
              />
            )}
            {policy.snapshotTransaction && (
              <PolicyInfoPayments
                testId={testId}
                transaction={policy.snapshotTransaction}
                displayMoreinfoButton={false}
                isUpcimingChange
              />
            )}
            {!isPlanAnnual && policy?.snapshotTransaction?.paymentPlan && (
              <NewMonthlyPayments
                usualPaymentFrequency={usualPaymentFrequency}
                testId={testId}
                paymentPlans={[policy?.snapshotTransaction?.paymentPlan]}
                classNames="bg-content-background-prominent"
              />
            )}
            <ConditionsCard testId={testId} />
            <PolicyDocuments
              testId={testId}
              policyId={id}
              noDownloadInfo
              version={version}
            />
            <FeesAndCharges
              testId={testId}
              feesForDisplay={policy?.applicableFees}
              isUpcomingChange
            />
            <div className="mb-12">
              <ExcessesCard testId={testId} noEdit />
            </div>
          </>
        )}
        <div className="mb-16">
          {policy?.snapshotTransaction?.paymentPlan && (
            <PaymentCard
              addOns={policy?.addOns}
              insurancePrice={policy?.snapshotTransaction?.price}
              paymentPlans={[policy?.snapshotTransaction?.paymentPlan]}
              cover={policy?.cover}
              totalPriceIncAddOns={policy.totalPrice}
              excesses={policy.excesses}
              testId={testId}
              mta
              usualPaymentFrequency={usualPaymentFrequency}
              classNames="bg-content-background-prominent"
            />
          )}
        </div>
        <Button
          variant="primary"
          classNames="w-full mb-8"
          id={`${testId}_back-to-policy-button`}
          testId={`${testId}_back-to-policy-button`}
          text="Back to policy"
          onClick={handleBackToPolicy}
        />
        <Button
          variant="secondary"
          classNames="w-full mb-16"
          id={`${testId}_back-to-policy-button`}
          testId={`${testId}_cancel-policy-changes-button`}
          text="Cancel policy changes"
          onClick={handleCancelChanges}
        />
      </ContentContainer>
      <CancelChangesDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        testId={testId}
      />
    </PageContainer>
  );
};
