import { formatInUKLocalTime } from '@dayinsure/shared';
import { format } from 'date-fns';
import {
  AddressDto,
  AddressDto as AddressDtoV1,
  CoverResponseDto,
  DriverDto,
  ExcessesResponseDto,
  NoClaimsHistoryDto,
  PaymentPlanDto,
  ProductDto,
  ReferenceCodeDto,
} from '../../../../api/v1';
import {
  formatPrice,
  getAdditionalDrivers,
  getClaimsBonusDescription,
  getCurrentPaymentPlan,
  getDriverAddress,
  getPaymentPlanNameFromCode,
  getProposer,
  getSelectedAddOnsNames,
  isNonNullable,
  getDriverDescription,
  fixYearsDispley,
  getLicenceHeldDescription,
} from '../../../../helpers';
import { PolicyMtaPaths, QuoteJourneyRoutes } from '../../../../routes';
import {
  ReviewPageSummaryCardData,
  ReviewPageSummaryCardItem,
  ReviewPageSummaryCardType,
} from './QuoteDetailsReview.types';
import { QuoteDetailsReviewList } from './QuoteDetailsReviewList';
import { UsualPaymentFrequency } from '../../../../types';
import { PaymentPlansEnum, PaymentTypesEnum } from '../../../../constants/payments';
import { AddOnsWithMeta } from '../../../../types/addOns';

const getCoverDateDetails = (
  dateTimeUtc?: string
): ReviewPageSummaryCardData[] | undefined => {
  if (!dateTimeUtc) {
    return undefined;
  }

  return [
    {
      title: 'Date',
      icon: 'date',
      content: formatInUKLocalTime('dd/MM/yyyy', dateTimeUtc),
      testId: 'date',
    },
    {
      title: 'Time (local UK time)',
      icon: 'time',
      content: formatInUKLocalTime('HH:mm', dateTimeUtc),
      testId: 'time',
    },
  ];
};

export const getCoverStartDateReviewCard = (
  startDateTimeUtc?: string,
  mta?: boolean,
  editLink?: string
): ReviewPageSummaryCardType | undefined => {
  if (!startDateTimeUtc) {
    return undefined;
  }

  return {
    isNarrow: true,
    title: mta ? 'Changes start' : 'Cover start',
    testId: 'cover-start',
    editLink,
    items: getCoverDateDetails(startDateTimeUtc),
  };
};

export const getCoverEndDateReviewCard = (
  cover?: CoverResponseDto
): ReviewPageSummaryCardType | undefined => {
  if (!cover) {
    return undefined;
  }

  return {
    isNarrow: true,
    title: 'Cover end',
    testId: 'cover-end',
    items: getCoverDateDetails(cover.endDateTimeUtc),
  };
};

const getPolicyholderReviewCardItem = (
  proposer?: DriverDto
): ReviewPageSummaryCardItem | undefined => {
  if (!proposer) {
    return undefined;
  }

  return {
    title: 'Policyholder',
    icon: 'user',
    testId: 'policyholder',
    content: getDriverDescription(proposer),
  };
};

const getDriverLicencePeriodReviewCardItem = (
  proposer?: DriverDto | undefined
): ReviewPageSummaryCardItem | undefined => {
  if (!proposer || !proposer.drivingLicence?.type?.description) {
    return undefined;
  }

  return {
    title: 'Period licence held',
    icon: 'date',
    testId: `drivers-details-card_list-item_licence-held`,
    content: getLicenceHeldDescription(
      proposer.drivingLicence?.yearsHeld,
      proposer.drivingLicence?.monthsHeld
    ),
  };
};

const getDriveLicenceTypeReviewCardItem = (
  proposer?: DriverDto | undefined
): ReviewPageSummaryCardItem | undefined => {
  if (!proposer || !proposer.drivingLicence?.type?.description) {
    return undefined;
  }

  return {
    title: 'Licence type',
    icon: 'card',
    testId: 'drivers-details-card_list-item_licence-type',
    content:
      proposer.drivingLicence?.type?.description || proposer.drivingLicence?.type?.code,
  };
};

const getDriverResidenceDateReviewCardItem = (
  driver?: DriverDto | undefined
): ReviewPageSummaryCardItem | undefined => {
  if (!driver || !driver.ukResidencyDetails?.ukResidentSince) {
    return undefined;
  }

  return {
    title: 'Date moved to UK',
    icon: 'date',
    testId: 'ukResidentSince',
    content: format(new Date(driver.ukResidencyDetails?.ukResidentSince), 'MM/yyyy'),
  };
};

const getDriverClaimsReviewCardItem = (
  driver?: DriverDto | undefined
): ReviewPageSummaryCardItem | undefined => {
  return {
    title: 'Claims',
    icon: 'clipboard',
    testId: 'drivers-details-card_list-item_claims',
    content:
      driver?.previousMotorClaims && driver.previousMotorClaims.length > 0 ? (
        <ul className="pl-4 list-disc">
          {driver.previousMotorClaims.map(claim => (
            <li key={claim.type?.code}>
              {claim.type?.description || claim.type?.code} (on{' '}
              {formatInUKLocalTime('dd/MM/yyyy', claim.date)})
            </li>
          ))}
        </ul>
      ) : (
        'No'
      ),
  };
};

const getDriverConvictionsReviewCardItem = (
  driver?: DriverDto | undefined
): ReviewPageSummaryCardItem | undefined => {
  return {
    title: 'Driving convictions',
    icon: 'clipboard',
    testId: 'drivers-details-card_list-item_convictions',
    content:
      driver?.motorConvictions && driver.motorConvictions.length > 0 ? (
        <ul className="pl-4 list-disc">
          {driver.motorConvictions.map(({ type, date }) => (
            <li key={type?.code}>
              {`Code ${type?.code}`} (on {`${formatInUKLocalTime('dd/MM/yyyy', date)}`})
            </li>
          ))}
        </ul>
      ) : (
        'No'
      ),
  };
};

const getAdditionalDriversReviewCardItem = (
  additionalDrivers: DriverDto,
  index: number
): ReviewPageSummaryCardItem | undefined => {
  if (!additionalDrivers) {
    return undefined;
  }

  return {
    startGroup: true,
    title: 'Additional driver',
    icon: 'user',
    testId: `additional-driver-${index}`,
    content: getDriverDescription(additionalDrivers),
  };
};

const getAddressReviewCardItem = (
  address?: AddressDtoV1
): ReviewPageSummaryCardItem | undefined => {
  if (!address?.formattedAddress) {
    return undefined;
  }

  return {
    title: 'Policyholder’s home address',
    icon: 'location',
    testId: 'home-address',
    content: getDriverAddress(address.formattedAddress),
  };
};

export const getDriversReviewCard = (
  drivers?: DriverDto[] | null,
  editLink?: string,
  policyHolderAddress?: AddressDto
): ReviewPageSummaryCardType | undefined => {
  if (!drivers) {
    return undefined;
  }

  const proposer = getProposer(drivers);
  const additionalDrivers = getAdditionalDrivers(drivers);
  let items: ReviewPageSummaryCardItem[] = [
    getPolicyholderReviewCardItem(proposer),
    getDriverResidenceDateReviewCardItem(proposer),
    getAddressReviewCardItem(policyHolderAddress || proposer?.address),
    getDriveLicenceTypeReviewCardItem(proposer),
    getDriverLicencePeriodReviewCardItem(proposer),
    getDriverClaimsReviewCardItem(proposer),
    getDriverConvictionsReviewCardItem(proposer),
  ].filter<ReviewPageSummaryCardItem>(isNonNullable);

  const additionalDriverItems: ReviewPageSummaryCardItem[] = [];

  additionalDrivers?.forEach((driver, index) => {
    const additionalDriverItem = getAdditionalDriversReviewCardItem(driver, index);

    if (additionalDriverItem) {
      additionalDriverItems.push(additionalDriverItem);
    }

    const residenceDateItem = getDriverResidenceDateReviewCardItem(driver);
    if (residenceDateItem) {
      additionalDriverItems.push(residenceDateItem);
    }

    const licenceType = getDriveLicenceTypeReviewCardItem(driver);
    if (licenceType) {
      additionalDriverItems.push(licenceType);
    }

    const perioidLicence = getDriverLicencePeriodReviewCardItem(driver);
    if (perioidLicence) {
      additionalDriverItems.push(perioidLicence);
    }

    const claims = getDriverClaimsReviewCardItem(driver);
    if (claims) {
      additionalDriverItems.push(claims);
    }

    const convictions = getDriverConvictionsReviewCardItem(driver);
    if (convictions) {
      additionalDriverItems.push(convictions);
    }
  });

  items = [...items, ...additionalDriverItems];

  return {
    title: 'Drivers',
    testId: 'drivers',
    editLink,
    items,
  };
};

const getNoClaimBonusReviewItem = (
  noClaimsHistory?: NoClaimsHistoryDto | null
): ReviewPageSummaryCardItem | undefined => {
  if (!noClaimsHistory?.yearsNoClaimsBonus) {
    return undefined;
  }
  const noClaimBonusPronun = getClaimsBonusDescription(
    noClaimsHistory?.yearsNoClaimsBonus
  );

  return {
    title: 'Number of years No Claims Bonus',
    icon: 'date',
    testId: 'no-claims-discount-years',
    content: `${fixYearsDispley(
      noClaimsHistory.yearsNoClaimsBonus
    )} ${noClaimBonusPronun}`,
  };
};
const getCoverReviewCardItem = (
  cover?: CoverResponseDto
): ReviewPageSummaryCardItem | undefined => {
  if (!cover?.type?.code) {
    return undefined;
  }

  return {
    title: 'Cover type',
    icon: 'policy',
    testId: 'cover-type',
    content: cover?.type.description,
  };
};

const getAddOnsReviewCardItem = (
  addOns?: Array<AddOnsWithMeta> | null
): ReviewPageSummaryCardItem | undefined => {
  const selectedAddOns = getSelectedAddOnsNames(addOns);
  if (!selectedAddOns || selectedAddOns.length === 0) {
    return undefined;
  }
  return {
    title: 'Add-ons',
    icon: 'addons',
    testId: 'addons',
    content: QuoteDetailsReviewList({ items: selectedAddOns }),
  };
};

export const getProductCoverCard = (
  product: ProductDto | undefined
): ReviewPageSummaryCardItem | undefined => {
  return {
    title: 'Product',
    testId: 'product',
    icon: 'product',
    content: <span className="capitalize">{product?.name}</span>,
  };
};

export const getInsurerCoverCard = (
  insurer: string | undefined | null
): ReviewPageSummaryCardItem | undefined => {
  if (!insurer) {
    return undefined;
  }
  return {
    title: 'Insurer',
    testId: 'insurer',
    icon: 'insurer',
    content: insurer,
  };
};

export const getCoverReviewCard = (
  {
    cover,
    addOns,
    product,
    noClaimsHistory,
  }: {
    cover?: CoverResponseDto;
    excesses?: ExcessesResponseDto;
    addOns?: Array<AddOnsWithMeta> | null;
    product?: ProductDto;
    noClaimsHistory?: NoClaimsHistoryDto | null;
  },
  mta?: boolean
): ReviewPageSummaryCardType | undefined => {
  const items: ReviewPageSummaryCardItem[] = [
    getProductCoverCard(product),
    getInsurerCoverCard(cover?.underwriter),
    getCoverReviewCardItem(cover),
    addOns?.length !== 0 ? getAddOnsReviewCardItem(addOns) : null,
    getNoClaimBonusReviewItem(noClaimsHistory),
  ].filter<ReviewPageSummaryCardItem>(isNonNullable);

  return {
    title: 'Cover',
    testId: 'cover',
    editLink: mta ? undefined : `/quote/${QuoteJourneyRoutes.Cover}`,
    items,
  };
};

const getPaymentTypeCardItem = (
  paymentFrequency?: ReferenceCodeDto,
  isMta?: boolean,
  isRefund?: boolean
): ReviewPageSummaryCardItem | undefined => {
  const paymentPlanName = getPaymentPlanNameFromCode(
    paymentFrequency?.code,
    isMta,
    isRefund,
    true
  );
  if (!paymentPlanName) {
    return undefined;
  }

  return {
    title: 'Payment type',
    icon: 'card',
    testId: 'payment-type',
    content: paymentPlanName,
  };
};

const getPaymentsCardItem = (
  selectedPlan?: PaymentPlanDto,
  isMta?: boolean
): ReviewPageSummaryCardItem | undefined => {
  const installmentDetails = selectedPlan?.installmentDetails;
  const isPlanAnnual = selectedPlan?.type?.code === PaymentPlansEnum.ANN;

  if (!installmentDetails && !isPlanAnnual) {
    return undefined;
  }

  const installmentItemsMonthly = () => {
    const totalAmount = `Total amount payable ${formatPrice(
      selectedPlan?.installmentDetails?.total?.amount || 0
    )}`;
    const deposit = `Initial payment ${formatPrice(
      installmentDetails?.deposit?.amount || 0
    )}`;

    const monthlyPayments = `${
      installmentDetails?.numberOfInstallments
    } monthly payments ${formatPrice(installmentDetails?.monthlyCost?.amount || 0)}`;

    return isMta ? [monthlyPayments] : [deposit, monthlyPayments, totalAmount];
  };

  const costDifference = !isPlanAnnual
    ? selectedPlan?.installmentDetails?.financeAmount?.amount
    : selectedPlan?.installmentDetails?.total?.amount;
  const isRefund = typeof costDifference === 'number' && costDifference < 0;

  const itemsAnnual = [
    isRefund
      ? `${formatPrice(Math.abs(selectedPlan?.installmentDetails?.total?.amount || 0))}`
      : `Total amount payable ${formatPrice(
          Math.abs(selectedPlan?.installmentDetails?.total?.amount || 0)
        )}`,
  ];

  const contentItems = isPlanAnnual ? itemsAnnual : installmentItemsMonthly();
  return {
    title: 'Payments',
    icon: 'policy',
    testId: 'payments',
    content: QuoteDetailsReviewList({ items: contentItems }),
  };
};

export const getPaymentsReviewCard = (
  paymentPlans?: PaymentPlanDto[] | null,
  usualPaymentFrequency?: UsualPaymentFrequency,
  mta?: boolean
) => {
  const paymentFrequency = usualPaymentFrequency || {
    code: {
      id: PaymentTypesEnum.ANNUAL,
      name: PaymentTypesEnum.ANNUAL,
      testId: PaymentTypesEnum.ANNUAL,
    },
  };
  const selectedPaymentPlan = getCurrentPaymentPlan(paymentFrequency, paymentPlans);

  if (!selectedPaymentPlan) {
    return undefined;
  }
  const isRefund = (selectedPaymentPlan?.installmentDetails?.total?.amount || 0) < 0;

  const items: ReviewPageSummaryCardItem[] = [
    getPaymentTypeCardItem(selectedPaymentPlan.type, mta, isRefund),
    getPaymentsCardItem(selectedPaymentPlan, mta),
  ].filter<ReviewPageSummaryCardItem>(isNonNullable);

  return {
    title: 'Payments',
    testId: 'payments',
    editLink: mta ? undefined : `../${QuoteJourneyRoutes.YourQuote}`,
    items,
  };
};

export const getCarEditLink = (
  policyChange: {
    changeCar: boolean;
    changeDrivers: boolean;
    changeReg: boolean;
  },
  isMta?: boolean
): string | undefined => {
  if (!isMta) {
    return '/quote/registration-search';
  }

  if (policyChange.changeCar) {
    return `../../${PolicyMtaPaths.NewCar}`;
  }

  if (policyChange.changeReg) {
    return `../../${PolicyMtaPaths.NewReg}`;
  }

  return undefined;
};

export const getDriversEditLink = (
  policyChange: {
    changeCar: boolean;
    changeDrivers: boolean;
    changeReg: boolean;
  },
  isMta?: boolean
): string | undefined => {
  if (!isMta) {
    return `/quote/${QuoteJourneyRoutes.Drivers}`;
  }

  if (policyChange.changeDrivers) {
    return `../../${PolicyMtaPaths.Drivers}`;
  }

  return undefined;
};

export const getCoverStartEditLink = (
  policyChange: {
    changeCar: boolean;
    changeDrivers: boolean;
    changeReg: boolean;
  },
  isMta?: boolean
): string | undefined => {
  if (!isMta) {
    return `/quote/${QuoteJourneyRoutes.Cover}`;
  }

  if (policyChange.changeCar) {
    return `../../${PolicyMtaPaths.NewCar}`;
  }

  if (policyChange.changeReg) {
    return `../../${PolicyMtaPaths.NewReg}`;
  }

  if (policyChange.changeDrivers) {
    return `../../${PolicyMtaPaths.Drivers}`;
  }

  return undefined;
};
