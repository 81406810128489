import { useEffect } from 'react';
import { ScrollToTop, useTheme } from '@dayinsure/shared';
import { SiteLayout } from '@dayinsure/components';
import TagManager from 'react-gtm-module';
import { ErrorDialog, Footer, Header } from './components';
import { useReferrer, useCookieConsent } from './hooks';
import { AppRoutes } from './routes/AppRoutes';
import { env } from './configuration/env';

const App = () => {
  const referrer = useReferrer();
  useTheme(referrer);
  // TODO: set proper value for COOKIE_CONSENT_KEY
  useCookieConsent();

  useEffect(() => {
    try {
      const tagManagerArgs = {
        gtmId: env.config.GTM_ID ?? '',
        auth: env.config.GTM_AUTH ?? '',
        preview: env.config.GTM_PREVIEW ?? '',
      };

      if (!tagManagerArgs.gtmId || !tagManagerArgs.auth || !tagManagerArgs.preview) {
        throw new Error('GTM integration error: Missing required environment variables.');
      }

      TagManager.initialize(tagManagerArgs);
    } catch (error) {
      console.error('GTM integration error', error);
    }
  }, []);

  return (
    <SiteLayout header={<Header />} footer={<Footer />}>
      <ScrollToTop>
        <AppRoutes />
      </ScrollToTop>
      <ErrorDialog />
    </SiteLayout>
  );
};

export default App;
