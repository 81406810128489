import { OpenAPI as OpenAPIV1 } from '../../api/v1';
import { OpenAPI as OpenAPIV2 } from '../../api/v2';
import { OpenAPI as OpenAPIV3 } from '../../api/v3';
import { env } from '../../configuration/env';

export const configureApi = () => {
  OpenAPIV1.BASE = env.config.API_BASE || '';
  OpenAPIV2.BASE = env.config.API_BASE || '';
  OpenAPIV3.BASE = env.config.API_BASE || '';
};
