import { Card, Icon } from '@dayinsure/components';
import { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import annualImportantInfoMock from '../../../constants/annualImportantInfo.json';
import { env } from '../../../configuration/env';
// TODO exchange mock for api data in integration task

// TODO exchange types for types from api
type PrePaymentLink = {
  Title: string;
  URL: string;
};
type PrePaymentKeyPoints = {
  OrderIndex: number;
  Text: string;
  Links: PrePaymentLink[];
};

type ImportantInformationProps = {
  className?: string;
  testId: string;
};

export const ImportantInformation = ({
  className,
  testId,
}: ImportantInformationProps) => {
  const documentPrefix = env.config.DOCUMENT_PREFIX || '';
  const renderImportantInfo = useMemo(() => {
    const { PrePaymentImportantInformation } = annualImportantInfoMock;
    return PrePaymentImportantInformation.map(info => (
      <div
        className="font-lato text-sm font-normal leading-5 text-main-content-1"
        key={info?.Title}
      >
        {info?.Title && <h4 className="my-6 font-bold">{info?.Title}</h4>}
        {info?.Description && <p className="mb-6">{info?.Description}</p>}
        {info?.Links && info?.Links.length > 0 && (
          <div className="flex flex-col">
            {info.Links.map((link: PrePaymentLink, i: number) => (
              <div className={clsx('flex items-center', { ' mt-6': i !== 0 })}>
                <Icon name="download" className="mr-2 text-link" />
                <a
                  key={link?.Title}
                  className="block text-base font-semibold underline text-link"
                  href={`${documentPrefix}${link.URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link?.Title}
                </a>
              </div>
            ))}
          </div>
        )}
        {info?.KeyPoints && info?.KeyPoints.length > 0 && (
          <ul className="pl-4 my-6 list-disc">
            {info?.KeyPoints?.map((keyPoint: PrePaymentKeyPoints) => (
              <Fragment key={keyPoint.Text}>
                <li key={keyPoint?.Text}>{keyPoint?.Text}</li>
                {keyPoint.Links && keyPoint.Links.length > 0 && (
                  <div className="flex relative right-4 mb-6">
                    <div>
                      {keyPoint.Links.map((link: PrePaymentLink) => (
                        <div className="flex items-center mt-6">
                          <Icon name="download" className="mr-2 text-link" />
                          <a
                            key={link.URL}
                            className="text-base font-semibold underline text-link"
                            href={`${documentPrefix}${link.URL}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.Title}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </ul>
        )}
      </div>
    ));
  }, [documentPrefix]);
  return (
    <section className={className}>
      <Card classNames="mb-8 md:mb-12" testId={`${testId}_information-card`}>
        <h3 className="mb-8 font-raleway font-normal text-main-content-1">
          Important information
        </h3>
        {renderImportantInfo}
        <p className="mt-6 mb-4 font-lato text-sm font-normal leading-5">
          This product <b>is suitable</b> for customers that need insurance for a private
          motor vehicle, who want to manage their motor insurance online via a website or
          mobile app, coupled with telephone support when making and managing a claim.
        </p>

        <p className="font-lato text-sm font-normal leading-5">
          This product <b>isn&apos;t suitable</b> for customers that need short-term or
          temporary motor insurance.
        </p>
      </Card>
    </section>
  );
};
